import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewEncapsulation, NgZone } from '@angular/core';
import { Article } from '../shared/article';
import { ArticleService } from '../shared/article.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, NEVER, of } from 'rxjs';
import { first, startWith, map, withLatestFrom, takeUntil, tap, shareReplay } from 'rxjs/operators';
import { NgForm, FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfigService } from 'src/app/admin/shared/config.service';
import * as _ from 'underscore';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { waitFor } from 'src/app/shared/helpers';
import { MediaPickerComponent } from 'src/app/media/media-picker/media-picker.component';
import { Image } from 'src/app/images/shared/image';
import { AppComponent } from 'src/app/app.component';
import { Media } from 'src/app/media/shared/media';
import { MediaService } from 'src/app/media/shared/media.service';
import { MailjobService } from 'src/app/admin/shared/mailjob.service';
import { Recipient } from 'src/app/admin/shared/mailjob';
import { AppService } from 'src/app/shared/app.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { Category } from 'src/app/admin/shared/config';
import { DialogService } from 'src/app/shared/dialog.service';
import { SurveyResult } from '../shared/surveyResult';
import { UserService } from 'src/app/user/shared/user.service';
import { ExcelService } from 'src/app/shared/excel.service';
import { DateFormatExportPipe } from 'src/app/shared/date-format-export.pipe';

@Component({
  selector: 'app-article-form',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ArticleFormComponent implements OnInit, OnDestroy {

  @ViewChild('articleForm') public articleForm: NgForm;
  article: Article;
  attachments: Media[] = [];
  surveyResults: Observable<SurveyResult[]> = of(null);
  surveyResultsCnt: Observable<number> = of(null);

  // TinyMCEs
  editorConfig = {
    inline: false,
    menubar: false,
    statusbar: false,
    browser_spellcheck: true,
    // plugins: 'lists link image powerpaste autoresize',
    plugins: 'lists link image autoresize',
    toolbar: ' styleselect | bold italic numlist bullist | link image | undo redo ',
    powerpaste_allow_local_images: false,
    autoresize_max_height: 600,
    autoresize_bottom_margin: 10,
    relative_urls : false,
    remove_script_host : false,
    style_formats: [
      { title: 'Überschrift', format: 'h3' },
      { title: 'Text', format: 'p' },
    ],
    file_picker_callback: this.openMediaPicker(this),
    language_url: '/assets/tinymce/langs/de.js'
  };

  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;
  @ViewChild('categoryAuto') categoryAuto: MatAutocomplete;
  categoryCtrl = new FormControl();
  categoryObs: Observable<Category[]>;
  allCategories: Category[] = [];
  filteredCategories: Observable<Category[]>;
  selectedCategories: Category[] = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagAuto') tagAuto: MatAutocomplete;
  tagCtrl = new FormControl();
  allTags: string[] = [];
  filteredTags: Observable<string[]>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  clicked = false;
  chipsDirty = false;

  constructor(private svc: ArticleService, private route: ActivatedRoute, private configSvc: ConfigService, private appSvc: AppService, private app: AppComponent
            , public dialog: MatDialog, private mediaSvc: MediaService, private userSvc: UserService
            , private mailjobSvc: MailjobService, private dialogSvc: DialogService, private notificationSvc: NotificationService
            , public excelSvc: ExcelService, private dateFormatExport: DateFormatExportPipe
            , private _ngZone: NgZone ) {
    // dynamically filter values for categories autocomplete
    this.categoryObs = this.configSvc.configObs
      .pipe(
        map( config => this.configSvc.categoryPaths.map( path => path.category )),
        tap( categories => this.allCategories = categories ),
        shareReplay(1)
      );
    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(null),
      waitFor(this.categoryObs),
      map((cat: string | null) => cat ? this._filterCategories(cat) : this.allCategories.slice())
    );

    // dynamically filter values for tags autocomplete
    const tagsObs = this.svc.getAllTags()
      .pipe( tap( tags => this.allTags = tags));
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      waitFor(tagsObs),
      map((tag: string | null) => tag ? this._filterTags(tag) : this.allTags.slice())
    );
  }

  ngOnInit() {

    // init article
    this.route.params.subscribe( params => {
      if (params['id']) {
        console.log('query id', params['id']);
        this.svc.getCached(params['id']).pipe(first()).subscribe( obj => {
          this.article = obj;
          this.mediaSvc.getCachedAll(this.article.attachmentRefs).pipe(first()).subscribe( medias =>
            this.attachments = medias
          );
          this.categoryObs.pipe(first()).subscribe( config => {
            this.selectedCategories = this.article.categories
              .map(cId => this.allCategories.find( cObj => cId === cObj.id));
          });
          // query survey results if this is a survey
          if (this.article.type == 'survey') {
            this.surveyResults = this.userSvc.getSurveyResults(this.article.id)
            //this.surveyResultsCnt = this.surveyResults.pipe(map( r => r.length() );
          }
          console.log('edit article', this.article);
        });
      } else {
        this.article = new Article();
      }
      this.clicked = false;
    });
  }

  private _eventDate: Date
  get eventDate(): Date {
    const date: any = this.article.date;
    if (!this._eventDate) {
      if (date && date.seconds) {
        this._eventDate = new Date(date.seconds * 1000);
      } else {
        this._eventDate = new Date(date);
      }
    }
    return this._eventDate;
  }
  set eventDate(d: Date) {
    this._eventDate = d;
    this.article.date = d.valueOf();
  }

  private _expirationDate: Date
  get expirationDate(): Date {
    const date: any = this.article.exiprationDate;
    if (!this._expirationDate) {
      if (date && date.seconds) {
        this._expirationDate = new Date(date.seconds * 1000);
      } else {
        this._expirationDate = new Date(date);
      }
    }
    return this._expirationDate;
  }
  set expirationDate(d: Date) {
    if (d) {
      this._expirationDate = d;
      this.article.exiprationDate = d.valueOf();
    } else {
      this._expirationDate = undefined;
      this.article.exiprationDate = undefined;
      this.articleForm.form.controls['expirationDate'].setErrors(null);
    }
  } 

  ngOnDestroy() { }

  submit() {
    this.clicked = true;
    //this.eventDate = this.eventDate;
    console.log('submit', this.article);
    this.save()
    .catch( e => this.notificationSvc.showError( "Artikel konnte nicht gespeichert werden: "+e ));
    // console.log( Array.from( this.editor.ui.componentFactory.names() ));
  }

  submitAndSend() {
    this.clicked = true;
    console.log('submitAndSend', this.article);
    this.save()
    .catch( e => {
      this.notificationSvc.showError( 'Artikel konnte nicht gespeichert werden: '+e );
      throw e;
    })
    .then( articleId => {
      // create title & content
      const title = this.configSvc.config.mailPrefix + ' ' + this.article.title;
      // special case if the event was cancelled
      const cancelled = this.article.title.toLocaleLowerCase().startsWith('abgesagt');
      let content = '<p>Liebe Eltern und Interessierte</p>\n';
      if (this.article.type === 'event' && this.article.date) {
        if (cancelled) {
          let eventName = this.article.title.replace(/abgesagt: /i, '');
          content = content + `<p>Die Veranstaltung <b>${eventName}</b>
                               vom <b>${this.configSvc.formatDateLong(this.article.date)}</b>
                               wird abgesagt.</p>\n`;
        } else {
          content = content + `<p>Am <b>${this.configSvc.formatDateLong(this.article.date)}</b>
                              findet die Veranstaltung <b>${this.article.title}</b> statt.</p>\n`;
        }
      }
      if (this.article.location && !cancelled) {
        content = content + '<p>Ort: ' + this.article.location + '\n';
      }
      content = content + '<p>' + this.article.body + '</p>\n';
      const attachmentsList = this.attachments.map( a => '<li><a href="' + a.url + '">' + a.name + '</a></li>').join('');
      const articleUrl = this.configSvc.config.baseUrl + '/article/' + articleId;
      content = content + '<p>Weitere Informationen: <ul><li>Artikel: <a href="' + articleUrl + '">' + articleUrl + '</a></li>' + attachmentsList + '</ul></p>\n';
      content = content + '<p>Besten Dank und freundliche Grüsse</p><p>Sekretariat Elternforum</p>\n';
      // get recipients
      const interestedUsers = this.svc.getInterestedUsers(this.article, userInfo => userInfo.user.mailNotify );
      interestedUsers.pipe(first()).toPromise().then( users => {
        const recipients: Recipient[] = users.map( user => ({ userKey: user.id, email: user.email }));
        console.log( `sending email to ${recipients.length} recipients`);
        // create mailjob
        return this.mailjobSvc.create({ title: title, content: content, contentKey: articleId, recipients: recipients })
          .then( v => recipients.length );
      }).then( cnt => this.notificationSvc.showInfo(`Email wird gleich an ${cnt} Empfänger versendet...`));
    });
  }

  save() {
    // save article
    if (this.article.id) {
      return this.svc.update(this.article).then( r => {this.app.back(); return this.article.id; });
    } else {
      return this.svc.add(this.article).then( r => {this.app.back(); return r.id; });
    }
  }

  cancel() {
    this.clicked = true;
    this.app.back();
  }

  // this opens the media picker inside TinyMCE Editor
  openMediaPicker(cmpThis: ArticleFormComponent) {
    // return a function to open the image picker dialog
    console.log('init openMediaPicker');
    return (callback, value, meta) => {
      this._ngZone.run(() => { 

        console.log('openMediaPicker', meta);
        // We need autoFocus=true in order to activate the nested angular MatDialog...
        const dlg = cmpThis.dialog.open(MediaPickerComponent, { autoFocus: true, hasBackdrop: true, minWidth: '400px', data: { type: 'image' }});
        return dlg.afterClosed().subscribe( result => {
          if (result) {
            const image = result as Image;
            console.log('image picked:', image.name);
            callback( Image.getThumbUrlMedium(image), {alt: image.name});
          }
        });
      });
    };
  }

  // next functions are for category chip-list with autocomplete
  removeCategory(cId: string): void {
    const index = this.article.categories.indexOf(cId);
    if (index >= 0) {
      this.article.categories.splice(index, 1);
      this.chipsDirty = true;
    }
    this.selectedCategories = this.selectedCategories.filter( c => c.id !== cId)
  }
  addCategoryInput(event: MatChipInputEvent): void {
    if ((event.value || '').trim() && !this.categoryAuto.isOpen) {
      if (!this.addCategory(event.value.trim())) {
        // clear control also if no match
        this.categoryInput.nativeElement.value = '';
        this.categoryCtrl.setValue(null);
      }
    }
  }
  addCategoryAuto(event: MatAutocompleteSelectedEvent): void {
    this.addCategory(event.option.value);
  }
  addCategory(cId: string): boolean {
    // avoid creating new categories
    const category = this.allCategories.find( cObj => cId === cObj.id);
    if (category) {
      this.article.categories.push(cId);
      this.categoryInput.nativeElement.value = '';
      this.categoryCtrl.setValue(null);
      this.chipsDirty = true;
      this.selectedCategories.push(category);
      return true;
    } else {
      return false;
    }
  }
  private _filterCategories(value: string): Category[] {
    const filterValue = value.toLowerCase();
    return _.chain(this.allCategories)
    .filter( cat => (cat.shortname || cat.name || cat.id).toLowerCase().indexOf(filterValue) === 0)
    .filter( cat => !_.contains(this.article.categories, cat.id)).value();
  }

  // next functions are for tags chip-list with autocomplete
  removeTag(tag: string): void {
    const index = this.article.tags.indexOf(tag);
    if (index >= 0) {
      this.article.tags.splice(index, 1);
      this.chipsDirty = true;
    }
  }
  addTagInput(event: MatChipInputEvent): void {
    if ((event.value || '').trim() && !this.tagAuto.options.some( i => i.active)) {
      if (!this.addTag(event.value.trim())) {
        // clear control also if no match
        this.tagInput.nativeElement.value = '';
        this.tagCtrl.setValue(null);
      }
    }
  }
  addTagAuto(event: MatAutocompleteSelectedEvent): void {
    this.addTag(event.option.viewValue);
  }
  addTag(tag: string): boolean {
    // allow create new tags
    if (!_.contains(this.allTags, tag)) {
      this.allTags.push(tag);
    }
    this.article.tags.push(tag);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.chipsDirty = true;
    return true;
  }
  private _filterTags(value: string): string[] {
    const filterValue = value.toLowerCase();
    return _.chain(this.allTags)
    .filter( tag => tag.toLowerCase().indexOf(filterValue) === 0)
    .difference(this.article.tags).value(); // avoid duplicate tags
  }

  selectAttachment() {
    this.dialogSvc.openMediaPicker( attachment => {
      // check if already existing
      if ( !this.attachments.find( (a) => a.id === attachment.id )) {
        this.attachments.push(attachment);
        this.article.attachmentRefs = this.attachments.map( a => a.id );
        this.chipsDirty = true;
      }
    });
  }
  removeAttachment(attachmentRef: string) {
    this.attachments = _.filter( this.attachments, attachment => attachment.id !== attachmentRef );
    this.article.attachmentRefs = this.attachments.map( a => a.id );
    this.chipsDirty = true;
  }

  exportSurveyResultsAsExcel(results: SurveyResult[]) {
    const data: Object[] = [];
    results.forEach( result => {
      const tstmp = this.dateFormatExport.transform(new Date(result.tstmp));
      const exportResult = {userId: result.userId, tstmp: tstmp};
      Object.keys(result.data).sort().forEach( k => exportResult[k] = result.data[k]);
      data.push(exportResult);
    })
    this.excelSvc.exportAsExcelFile(data, 'surveyResults');
  }
}
