import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService} from './user.service';
import { tap, map, take, first } from 'rxjs/operators';
import { ArticleService } from 'src/app/articles/shared/article.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { DialogService } from 'src/app/shared/dialog.service';

@Injectable()
export class ArticleGuard implements CanActivate {
  constructor( private userSvc: UserService, private notificationSvc: NotificationService
             , private dialogSvc: DialogService, private articleSvc: ArticleService) {}

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    if (next.params['id']) {
      return this.articleSvc.getCached(next.params['id']).pipe(first()).toPromise()
        .then(obj => true)
        .catch(err => {
          if (this.userSvc.isLoggedIn()) {
            this.notificationSvc.showError( `Keine Berechtigung für diesen Artikel (${err})` );
            return false;
          } else {
            this.notificationSvc.showInfo( `Bitte melden sie sich an!` );
            return this.dialogSvc.openLoginDialog().then( result => {
              if (result) {
                return this.canActivate( next, state );
              } else {
                this.notificationSvc.showError( `Keine Berechtigung für diesen Artikel (${err})` );
                return false;
              }
            });
          }
        });
    } else {
      console.error('parameter id not defined');
      return false;
    }

  }
}
