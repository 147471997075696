import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormatShort'
})
export class DateFormatShortPipe extends DatePipe implements PipeTransform {
  transform(value: Date): any {
    return super.transform(value, 'd.M.yyyy, HH:mm' );
  }
}
