import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestoreCollection, AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { ConfigService } from './config.service';
import { AppService } from 'src/app/shared/app.service';
import { Mailjob, Recipient } from './mailjob';
import { map, tap, shareReplay } from 'rxjs/operators';
import * as _ from 'underscore';
import { UserService } from 'src/app/user/shared/user.service';

@Injectable({
  providedIn: 'root'
})
export class MailjobService {
  private name = 'mailjobs';

  collection: AngularFirestoreCollection<Mailjob> = null;
  mailjobs: Observable<Mailjob[]>;

  constructor(private db: AngularFirestore, private config: ConfigService, private app: AppService, private userService: UserService ) {
    // init
    this.collection = db.collection<Mailjob>(this.name);
  }


  // cached list for all objects
  getList(): Observable<Mailjob[]> {
    if (!this.mailjobs) {
      const filterFunc = ref => ref;
      this.mailjobs = this.db.collection<Mailjob>( this.name, filterFunc)
      .snapshotChanges()
      .pipe(
        map( changes => changes.map( obj => ({ id: obj.payload.doc.id, ...obj.payload.doc.data() }))),
        map( objs => _.sortBy(objs, obj => obj.created).reverse()),
        shareReplay(1) // cache all
      );
    }
    return this.mailjobs;
  }

  // this is uncached!
  get(id: string): Observable<Mailjob> {
    return this.collection.doc<Mailjob>(id).snapshotChanges()
    .pipe(map( obj => ({ id: obj.payload.id, ...obj.payload.data()})));
  }

  getCached( id: string): Observable<Mailjob> {
    return this.getList().pipe( map( objs => objs.find( obj => obj.id === id )));
  }

  create( obj: Partial<Mailjob> ): Promise<DocumentReference> {
    obj.created = new Date();
    obj.updated = new Date();
    obj.owner = this.userService.userInfo.user.id;
    if (!obj.status) {
      obj.status = 'prepared';
    }
    // overwrite recipients if not on prod
    if (!this.app.isProduction()) {
      obj.recipients = [{ userKey: 'admin', email: this.app.getAdminMail() }];
      console.log('email job routed to admin only on test environment');
    }
    // add info mail to recipients if not on prod
    if (this.app.isProduction()) {
      obj.recipients.push(new Recipient('info', this.app.getInfoMail()));
    }
    var plainObj = Object.assign({}, obj);
    plainObj.recipients = obj.recipients.map(r => Object.assign({}, r));
    return this.collection.add(plainObj as any);
  }

  update( obj: Partial<Mailjob> ) {
    obj.updated = new Date();
    return this.collection.doc<Mailjob>(obj.id).update(obj);
  }

  delete( obj: Mailjob ) {
    this.collection.doc(obj.id).delete();
  }

}
