import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UserService } from '../shared/user.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, NgForm, FormControl, FormGroupDirective } from '@angular/forms';
import { User, CategoryRegistration } from '../shared/user';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { tap, filter, first, map } from 'rxjs/operators';
import { ConfigService } from '../../admin/shared/config.service';
import { Category } from 'src/app/admin/shared/config';
import { NotificationService } from 'src/app/shared/notification.service';
import * as _ from 'underscore';
import { DialogService } from 'src/app/shared/dialog.service';
import { combineLatest, Observable } from 'rxjs';
import { Route } from '@angular/compiler/src/core';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {

  signupForm: FormGroup;
  userDetail: User;
  @ViewChild('detailForm') public detailForm: NgForm;
  categoryTblCols = ['description', 'category', 'action'];
  categoryTblDS = new MatTableDataSource<CategoryRegistration>([]);
  categoryOptions: Category[];
  matcher = new MyErrorStateMatcher();
  error = '';

  // @ViewChild(MatSort) categoryTblSort: MatSort; // this doesn't work with ngIf
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.categoryTblDS.sort = sort;
  }
  @ViewChild(MatTable) categoryTbl: MatTable<CategoryRegistration>;

  constructor( public fb: FormBuilder, public userService: UserService, public configService: ConfigService, private dialogSvc: DialogService, private app: AppComponent
             , public dialog: MatDialog, private router: Router, public notificationSvc: NotificationService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.userDetail = null;

    // check if logged in or registrationcode set
    if (this.isNotRegistered()) {
      this.router.navigate(['register'])
    }

    // First Step
    this.signupForm = this.fb.group({
      'email': [ '', [ Validators.required, Validators.email ]],
      'password': ['', [
        Validators.pattern('^.*[0-9].*$'),
        Validators.minLength(6),
        Validators.maxLength(25),
        Validators.required ]],
      'password2': ['', [ Validators.required ]]
      }, { validator: this.checkPasswords });

    // Second Step
    // fill data into form when available
    // if we dont receive an id as url parameter, we get the current user
    this.route.params.subscribe( params => {
      var userObs: Observable<User>;
      if (params['id']) {
        console.log('query id', params['id']);
        userObs = this.userService.get(params['id']);
      } else {
        // get current user
        userObs = this.userService.userInfoObs.pipe(
          filter(userInfo => userInfo != null),
          map(userInfo => userInfo.user)
        )
      }
      userObs.pipe( filter( user => (user ? true : false))).forEach(
        user => {
          const userClone = JSON.parse(JSON.stringify(user)); // clone object
          this.userDetail = userClone;
          // set default value for mailNotify
          if (this.userDetail.mailNotify === undefined) {
            this.userDetail.mailNotify = true;
          }
          this.categoryTblDS.data = userClone.categories || [];
          // init category options
  
          // wait for init
          combineLatest([this.userService.initialized, this.configService.configObs])
          .subscribe( ([user, config]) => {
            this.setCategoryOptions(this.userDetail.parentalRole)
          });
        }
      );
    });

    this.error = '';
  }

  get email() { return this.signupForm.get('email'); }
  get password() { return this.signupForm.get('password'); }
  get password2() { return this.signupForm.get('password2'); }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.password.value;
    let pass2 = group.controls.password2.value;
    return pass === pass2 ? null : { notSame: true }     
  }

  // functions to check what the form should do
  isNotRegistered() {return !this.userService.isLoggedIn() && !this.userService.hasRegistrationCodeHash()}
  isSignup() {return !this.userService.isLoggedIn() && this.userService.hasRegistrationCodeHash()}
  isDetails() {return this.userService.isLoggedIn() && this.userDetail != null}

  signup() {
    this.error = '';
    this.userService.emailSignUp(this.email.value, this.password.value)
    .then( x => this.userService.sendEmailVerification())
    .then( x => this.notificationSvc.showInfo("Email zur Bestätigung der Anmeldung gesendet"))
    .catch( error => {
      console.error(error);
      if (error.code === 'auth/email-already-in-use') {
        this.error = "EMail-Adresse ist bereits registriert"
      } else if (error.message) {
        this.error = error.message;
      } else {
        this.error = error;
      }
    });
  }

  openLoginDialog() { 
    this.dialogSvc.openLoginDialog();
  }

  setDetails() {
    if (this.userDetail.parentalRole === 'interested' && this.userDetail.categories) {
      this.userDetail.categories.forEach( c => {c.description = null; c.representative = false; });
    }
    if (!this.userDetail.id) this.userDetail.id = this.userService.userInfo.user.id
    return this.userService.update( this.userDetail )
    .then( () => {
      console.log('user updated');
      this.detailForm.form.markAsPristine();
      this.router.navigate(['my']);
    });
  }

  checkDetailsExist( user: User ) {
    return user.checkDetailsExist();
  }

  parentalRoleChanged(change: MatSelectChange) {
    this.setCategoryOptions(change.value);
  }

  setCategoryOptions(parentalRole: String) {
    if (parentalRole === 'interested' || this.userService.userInfo.isEditor()) {
      const categories = this.configService.categoryPaths.map(c => c.category)
      .filter(c => c.id != 'vorstand' && c.id != 'ev');
      this.categoryOptions = _.sortBy(categories, c => c.shortname || c.id);
      //console.log( 'categoryOptions interested', this.categoryOptions)
    } else {
      const categories = this.configService.leafCategoryPaths.map(c => c.category)
      .filter(c => c.id != 'vorstand' && c.id != 'ev');
      this.categoryOptions = _.sortBy(categories, c => c.shortname || c.id);
      //console.log( 'categoryOptions', this.categoryOptions)
    }
  }

  addCategory() {
    this.userDetail.categories = (this.userDetail.categories || []).concat({description: null, category: null, representative: false});
    this.categoryTblDS.data = this.userDetail.categories;
    this.categoryTbl.renderRows();
  }

  deleteCategory( idx: number ) {
    // TODO: nach sortierung der Tabelle stimmt der Index nicht mehr und das falsche Element wird gelöscht!
    this.userDetail.categories.splice(idx, 1);
    this.categoryTblDS.data = this.userDetail.categories;
    this.categoryTbl.renderRows();
    this.detailForm.form.markAsDirty(); // removing fields is not recognized as change by the form, we have to touch it manually...
  }

  cancel() {
    this.app.back();
  }  

  deleteProfile() {
    if (this.userDetail.id === this.userService.userInfo.user.id) {
      // Eigenes Profil löschen
      const notification = this.notificationSvc.showInfo('Profil '+this.userService.userInfo.user.email+' wird gelöscht', 'Abbrechen')
      notification.afterDismissed().subscribe( x => {
        if (!x.dismissedByAction) {
          // delete profile
          this.userService.deleteCurrentUser()
          // logout
          .then( x => this.userService.logout())
          // then navigate home
          .then( x => {
              console.log('Logged out. Navigating to "Home"');
              this.router.navigate(['home']);
          });
          console.log("Profil deleted");
        } else {
          console.log("Deleting profile cancelled");
        }
      });
    } else {
      // Fremdes Profil löschen
      const notification = this.notificationSvc.showInfo('Profil '+this.userDetail.email+' wird gelöscht', 'Abbrechen')
      notification.afterDismissed().subscribe( x => {
        if (!x.dismissedByAction) {
          // delete profile
          this.userService.deleteUser(this.userDetail.id)
          .then( x => {
            this.notificationSvc.showInfo("User Daten sind gelöscht, das Profil muss aber noch in der Adminkonsole manuell gelöscht werden.");
          });
          this.app.back();
        } else {
          console.log("Deleting profile cancelled");
        }
      });
    }
  }      
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const error = !!(control && control.parent.invalid && control.touched);
    return error;
  }
}