<div fxFlex></div>
<mat-card fxFlex="0 1 600px">
  <form [formGroup]="registerForm" fxLayout="column" fxLayoutAlign="start">
      
    <h3>Registrierung für Informationen vom Elternforum</h3>
    <div *ngIf="userService.isLoggedIn()">
      <div>
        Das neue Schuljahr hat begonnen und damit muss ihre Registration für die Webseite des Elternforums aktualisiert werden.
        Bitte geben Sie den neuen Registrationscode ein und aktualisieren Sie in ihrem Profil die Klassen ihrer Kinder.
        Der Registrationscode wurde Ihnen per Mail zugestellt.
      </div>
    </div>
    <mat-form-field style="max-width:300px">
      <input matInput type="text" placeholder="Registrationscode" formControlName="code">
    </mat-form-field>
    <div>
      <button mat-raised-button class="mat-primary" appPreventDoubleClick (throttledClick)='register()' [disabled]="!registerForm.valid">Registrieren</button>
      <div fxFlex></div>
    </div>
    <div>
      <br/>
      <div class="mat-small">
        Mit der Registration erhalten Sie Zugriff auf alle Informationen des Elternforums und werden über die relevanten Informationen der Klassen ihrer Kinder per Email informiert.
        Sie erklären sich einverstanden, dass ihre Profildaten mit den Mitgliedern des Elternforums (Vorstand und Elternverteter) zur Vernetzung geteilt werden.
      </div>
      <div class="mat-small">
        Falls Sie keinen Registrationscode besitzen, können sie diesen beim Sekretariat mit einer kurzen Begründung anfordern.
      </div>
    </div>
  </form>
</mat-card>
<div fxFlex></div>