<div fxFlex></div>
<mat-card *ngIf="obj" fxFlex="0 1 600px">
    <form #form="ngForm" (ngSubmit)="submit()" fxLayout="column" fxLayoutAlign="start">
        
      <h3>Dokument bearbeiten</h3>
  
      <div>Url: <a [href]="obj.url">{{obj.path}}</a></div>
      <div>Typ: {{obj.type}}</div>
      <div>Thumbs: {{mapkeys2Str(obj.thumbs)}}</div>
      <br/>

      <mat-form-field style="max-width:400px">    
        <input matInput type="text" placeholder="Name" required [(ngModel)]="obj.name" name="name">
      </mat-form-field>
  
      <mat-checkbox [(ngModel)]="obj.active" [checked]="obj.active" name="active" >Aktiv<span class="small-text"> - Inaktive Einträge werden in Listen standardmässig nicht angzeigt</span></mat-checkbox>
      <mat-checkbox [(ngModel)]="obj.public" [checked]="obj.public" name="public">Publik<span class="small-text"> - Private Einträge sind nur für eingeloggte Benutzer sichtbar</span></mat-checkbox>
      <br/>

      <!-- tags -->
      <mat-form-field>
        <mat-chip-list #tagList>
          <mat-chip *ngFor="let tag of obj.tags" removable="true" (removed)="removeTag(tag)"> {{tag}} <mat-icon matChipRemove>cancel</mat-icon></mat-chip>
          <input placeholder="Tags..." [matChipInputFor]="tagList" #tagInput [matAutocomplete]="tagAuto" [formControl]="tagCtrl"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true" (matChipInputTokenEnd)="addTagInput($event)">
        </mat-chip-list>
        <mat-autocomplete #tagAuto="matAutocomplete" (optionSelected)="addTagAuto($event)">
          <mat-option *ngFor="let tag of filteredTags | async" [value]="tag"> {{tag}} </mat-option>
        </mat-autocomplete>                 
      </mat-form-field>     

      <br/>
      <div>
        <button mat-raised-button (click)='cancel()'>Abbrechen</button>
        <div fxFlex></div>
        <button mat-raised-button class="mat-primary" appPreventDoubleClick (throttledClick)='submit()' [disabled]="!form.valid || !(form.dirty || chipsDirty)">Speichern</button>
      </div>
    </form>
  </mat-card>
<div fxFlex></div>
