import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, RouterState } from '@angular/router';
import { UserService } from '../user/shared/user.service';
import { MediaPickerComponent } from '../media/media-picker/media-picker.component';
import { Media } from '../media/shared/media';
import { LoginFormComponent } from '../user/login-form/login-form.component';
import { environment } from '../../environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private functions: AngularFireFunctions) { }

  isProduction() {
    return environment.production;
  }

  getAdminMail() {
    return environment.adminEmail;
  }


  getInfoMail() {
    return environment.infoEmail;
  }  

  remoteLogEnabled() {
    return environment.remoteLogging;
  }

  remoteLog(msg: string) {
    if (this.remoteLogEnabled()) {
      this.functions.httpsCallable('remoteLog')({message: msg})
    }
  }

}
