<div fxFlex></div>
<mat-card fxFlex="0 1 600px">

    <div *ngIf="obj; else nodata" class="main">
        <div *ngIf="obj.type!='survey' || userSvc.isLoggedIn(); else surveyNotLoggedIn">
            <h3 class="header">{{ obj.title || 'missing title' }}</h3>
            <p *ngIf="obj.date"><b>Datum:</b> {{ obj.date | dateFormatLong }}</p>
            <p *ngIf="obj.location"><b>Ort:</b> {{ obj.location }}</p>
            <p *ngIf="obj.type!='survey'" [innerHTML]="obj.body"></p>
            <surveyjs-component #survey *ngIf="obj.type=='survey'" [spec]="obj.body" [onComplete]="storeSurveyResult.bind(this)" [getPreviousResult]="loadExistingSurveyResult.bind(this)"></surveyjs-component>
            <div *ngIf="attachments.length>0 || hiddenAttachments" fxLayout fxLayoutAlign="start center" class="paragraph">
                <div><b>Attachments:&nbsp;</b></div>
                <div>
                    <mat-chip-list><mat-chip class="chip-attachment" *ngFor="let a of attachments" (click)='openAttachment(a)'>{{a.name}} [{{a.type}}]</mat-chip></mat-chip-list>
                    <span *ngIf="attachmentsHidden">Dieser Artikel enthält nicht öffentliche Attachments. Bitte <a (click)="app.openLoginDialog(true)" class="link">loggen sie sich ein</a>, um alle Attachments zu sehen.</span>
                </div>
            </div>
            <div *ngIf="obj.categories.length>0" fxLayout fxLayoutAlign="start center" class="paragraph">
                <div><b>Kategorien:&nbsp;</b></div>
                <div><mat-chip-list><mat-chip *ngFor="let c of categories" (click)='false' [matTooltip]="c.name">{{ c.shortname || c.id }}</mat-chip></mat-chip-list></div>
            </div>
            <div *ngIf="obj.tags.length>0" fxLayout fxLayoutAlign="start center" class="paragraph">
                <div><b>Tags:&nbsp;</b></div>
                <div><mat-chip-list><mat-chip *ngFor="let tag of obj.tags" (click)='false'>{{ tag }}</mat-chip></mat-chip-list></div>
            </div>
            <div>
                <div fxLayoutAlign="end end"><p *ngIf='obj.tstmp' class="footer">aktualisiert am {{ obj.tstmp | dateFormatShort }}</p></div>
                <div fxFlex></div>
                <div>
                    <div fxFlex></div>
                    <button *ngIf="userSvc.userInfo && userSvc.userInfo.isEditor()" mat-raised-button appPreventDoubleClick (throttledClick)='editObj(obj.id)'>Bearbeiten</button>
                    <div class="button-spacer"></div>
                    <button *ngIf="obj.type!='survey' || (surveyComponent != null && surveyComponent.hasResult())" mat-raised-button class="mat-primary" (click)='cancel()'>Zurück</button>
                </div>  
            </div>
        </div>
    </div>
    <ng-template #surveyNotLoggedIn><div fxLayoutAlign="center">Bitte einloggen, um Umfragen zu beantworten.</div></ng-template>
    <ng-template #nodata><div fxLayoutAlign="center">Loading...</div></ng-template>

</mat-card>
<div fxFlex></div>
