import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisableDrop]'
})
export class DisableDropDirective {

  constructor() { }

  @HostListener('drop', ['$event']) onDrop($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  @HostListener('dragover', ['$event']) onDragOver($event: DragEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    $event.dataTransfer.dropEffect = 'none';
  }

}
