import { Injectable } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackbar: MatSnackBar, private appSvc: AppService) { }

  showError(msg: string, wait = false) {
    console.log('Error: ' + msg);
    this.appSvc.remoteLog('Error: ' + msg);
    const config = new MatSnackBarConfig();
    var action = undefined
    if (wait) {
      action = "Schliessen";
    } else {
      config.panelClass = ['background-red'];
      config.duration = 4000;
    }
    return this.snackbar.open(msg, action, config);
  }

  showInfo(msg: string, action: string = '', wait = false) {
    console.log(msg);
    const config = new MatSnackBarConfig();
    config.panelClass = ['background-green'];
    if (!wait) config.duration = 4000;
    return this.snackbar.open(msg, action, config);
  }
}
