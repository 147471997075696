<mat-card>

  <div fxLayoutAlign="center" class="paddingM">
    <span fxFlexAlign="center">Filter&nbsp;</span>
    <mat-chip-list multiple="true">
      <mat-chip *ngFor="let t of tags" (click)="selectTag(t)" [selected]="t.selected" [matTooltip]="t.name">{{ t.shortname || t.id }}</mat-chip>
    </mat-chip-list>
  </div>

  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
      <mat-cell *matCellDef="let obj"> {{obj.id}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="type"> 
      <mat-header-cell *matHeaderCellDef mat-sort-header> Typ </mat-header-cell>
      <mat-cell *matCellDef="let obj"> {{obj.type}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Titel </mat-header-cell>
      <mat-cell *matCellDef="let obj"> <div class="clickable cell-overflow" (click)="gotoArticle(obj)">{{getSummary(obj)}}</div> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="categories">
      <mat-header-cell *matHeaderCellDef> Kategorien </mat-header-cell>
      <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{getCategoryNames(obj.categories).sort().join(', ')}}</div> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tags">
      <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
      <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{obj.tags.sort().join(', ')}}</div> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tstmp">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Änderungszeit </mat-header-cell>
      <mat-cell *matCellDef="let obj"> {{obj.tstmp | dateFormatShort}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Aktiv </mat-header-cell>
      <mat-cell *matCellDef="let obj"><mat-icon *ngIf="obj.active">check</mat-icon></mat-cell>
    </ng-container>
    <ng-container matColumnDef="public">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Publik </mat-header-cell>
      <mat-cell *matCellDef="let obj"><mat-icon *ngIf="obj.public">check</mat-icon></mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions" stickyEnd>
      <mat-header-cell *matHeaderCellDef>
        <button type="button" (click)="newObj()" mat-icon-button><mat-icon>add_circle_outline</mat-icon></button>
      </mat-header-cell>
      <mat-cell *matCellDef="let obj;">
        <button type="button" (click)="editObj(obj.id)" mat-icon-button><mat-icon>create</mat-icon></button>
        <button type="button" [matMenuTriggerFor]="matArticleMore" [matMenuTriggerData]="{obj: obj}" mat-icon-button><mat-icon>more_vert</mat-icon></button>          
      </mat-cell>
    </ng-container>  
              
    <mat-header-row *matHeaderRowDef="cols"></mat-header-row>
    <mat-row *matRowDef="let row; columns: cols;"></mat-row>
  </mat-table>
  <mat-menu #matArticleMore="matMenu">
    <ng-template matMenuContent let-obj="obj">
      <button mat-menu-item (click)="cloneObj(obj)">Klonen</button>
      <button mat-menu-item (click)="svc.delete(obj)">Löschen</button>
    </ng-template>        
  </mat-menu>

  <div *ngIf="!dataSource.initialized" fxLayoutAlign="center">Loading...</div>

  <mat-paginator *ngIf="objsLength > pageSize" [length]="objsLength" [pageSize]="pageSize" hidePageSize="true" (page)="tablePageChanged($event)" showFirstLastButtons></mat-paginator>    

</mat-card>