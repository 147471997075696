
import { environment } from '../environments/environment';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { ArticlesListComponent } from './articles/articles-list/articles-list.component';
import { ArticleDetailComponent } from './articles/article-detail/article-detail.component';
import { ArticleFormComponent } from './articles/article-form/article-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ArticlesBlogComponent } from './articles/articles-blog/articles-blog.component';
import { ImagePickerComponent } from './images/image-picker/image-picker.component';
import { DropZoneDirective } from './shared/drop-zone.directive';
import { HoverClassDirective } from './shared/hover-class.directive';
import { DisableDropDirective } from './shared/disable-drop.directive';
import { UserFormComponent } from './user/user-form/user-form.component';
import { AdminFormComponent } from './admin/admin-form/admin-form.component';
import { UserService } from './user/shared/user.service';
import { AngularFireAuthModule  } from '@angular/fire/auth';
import { LoginFormComponent } from './user/login-form/login-form.component';
import { RegisterFormComponent } from './user/register-form/register-form.component';
import { AppService } from './shared/app.service';
import { PagesComponent } from './pages/pages.component';
import { SanitizeHtmlPipe } from './shared/sanitize.pipe';
import { AdminGuard } from './user/shared/admin-guard.service';
import { EditorGuard } from './user/shared/editor-guard.service';
import { registerLocaleData } from '@angular/common';
import localeDeCH from '@angular/common/locales/de-CH';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { MediaPickerComponent } from './media/media-picker/media-picker.component';
import { DateFormatLongPipe } from './shared/date-format-long.pipe';
import { ArticleGuard } from './user/shared/article-guard.service';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { DateFormatShortPipe } from './shared/date-format-short.pipe';
import { ConfigService } from './admin/shared/config.service';
import { UserListComponent } from './user/user-list/user-list.component';
import { PreventDoubleClickDirective } from './shared/prevent-double-click.directive';
import { ErrorsHandler } from './shared/error-handler';
import { MediaListComponent } from './media/media-list/media-list.component';
import { MediaFormComponent } from './media/media-form/media-form.component';
import { EvGuard } from './user/shared/ev-guard.service';
import { MsgDialogComponent } from './shared/msg-dialog/msg-dialog.component';
import { RegistrationGuard } from './user/shared/registration-guard.service';
import { first } from 'rxjs/operators';
import { SurveyjsComponent } from './articles/article-detail/surveyjs.component';
import { DateFormatExportPipe } from './shared/date-format-export.pipe';
import { TextEntryDialogComponent } from './shared/text-entry-dialog/text-entry-dialog.component';


registerLocaleData(localeDeCH);

/*
export const MY_MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    date: ['YYYY-MM-DD', 'YYYY/MM/DD', 'll'],
    datetime: ['YYYY-MM-DD HH:mm', 'YYYY/MM/DD HH:mm'],
    time: ['H:mm', 'HH:mm']
  },
  display: {
    date: 'll',
    datetime: 'll HH:mm',
    time: 'HH:mm',
    dateA11yLabel: 'LL',
    monthDayLabel: 'D MMM',
    monthDayA11yLabel: 'D MMMM',
    monthYearLabel: 'MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
    timeLabel: 'HH:mm'
  }
};*/

// app initializer factory functions
export function configFactory(config: ConfigService) {
  return () => config.configObs.pipe(first()).toPromise();
}
export function userFactory(user: UserService) {
  return () => user.initialized.pipe(first()).toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    ArticlesListComponent,
    ArticleDetailComponent,
    ArticleFormComponent,
    ArticlesBlogComponent,
    MediaPickerComponent,
    ImagePickerComponent,
    DropZoneDirective,
    HoverClassDirective,
    DisableDropDirective,
    UserFormComponent,
    AdminFormComponent,
    LoginFormComponent,
    RegisterFormComponent,
    PagesComponent,
    SanitizeHtmlPipe,
    NotFoundComponent,
    DateFormatLongPipe,
    DateFormatShortPipe,
    DateFormatExportPipe,
    UserListComponent,
    PreventDoubleClickDirective,
    MediaListComponent,
    MediaFormComponent,
    MsgDialogComponent,
    SurveyjsComponent,
    TextEntryDialogComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, AngularFireStorageModule, AngularFireFunctionsModule, AngularFireFunctionsModule, AngularFireAuthModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    FlexLayoutModule,
    MatButtonModule, MatCheckboxModule, MatToolbarModule, MatSidenavModule, MatIconModule,
    MatCardModule, MatPaginatorModule, MatDialogModule, MatProgressBarModule, MatTooltipModule,
    MatFormFieldModule, MatInputModule, MatMenuModule, MatSelectModule, MatTableModule, MatSortModule,
    MatSnackBarModule, MatChipsModule, MatTableModule, MatAutocompleteModule, MatProgressSpinnerModule,
    EditorModule, MatDatepickerModule, MatNativeDatetimeModule, MatDatetimepickerModule, MatPaginatorModule,
    MatListModule, MatExpansionModule
  ],
  providers: [
    AdminGuard, EditorGuard, EvGuard, ArticleGuard, RegistrationGuard, UserService, MatSnackBar, AppService, DateFormatShortPipe, DateFormatExportPipe, ConfigService,
    { provide: LOCALE_ID, useValue: 'de-CH' },
    { provide: APP_INITIALIZER, useFactory: configFactory, deps: [ConfigService], multi: true }, // wait for config service loaded
    { provide: APP_INITIALIZER, useFactory: userFactory, deps: [UserService], multi: true }, // wait for user service init
    { provide: REGION, useValue: 'europe-west1' },
    { provide: ErrorHandler, useClass: ErrorsHandler }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ MediaPickerComponent, ImagePickerComponent, LoginFormComponent, MsgDialogComponent ]
})
export class AppModule { }
