<div appDisableDrop>
<h2 mat-dialog-title>Bild auswählen</h2>
<mat-dialog-content>

  <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center">

    <!-- upload card -->
    <mat-card class="upload-card" *ngIf='true' appDropZone dragOverClass="mat-elevation-z6" (dropped)="startUpload($event)">
      <mat-card-content *ngIf="!percentage" class="upload-card-content" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>add</mat-icon>
        <div class="center-text">Datei hier hinziehen</div>
      </mat-card-content>
      <mat-card-content *ngIf="percentage" class="upload-card-content" fxLayout="column" fxLayoutAlign="center center">
        <mat-progress-bar mode="determinate" [value]="percentage | async"></mat-progress-bar>
        <button id="btn-upload-cancel" (click)="task.cancel()" mat-mini-fab><mat-icon>clear</mat-icon></button>          
      </mat-card-content>
    </mat-card>

    <!-- existing pictures -->
    <mat-card class="upload-card" *ngFor="let obj of objs | async | slice:0:limit" appHoverClass="mat-elevation-z6">
      <img class="image-card-image" [src]="getThumbUrlSmall(obj)">
      <div class="image-card-footer">{{obj.name}}</div>
      <mat-spinner *ngIf="!obj.thumbs" [diameter]="20" matTooltip="Warten auf resizing..."></mat-spinner>      
      <button mat-mini-fab *ngIf="obj.thumbs" class="image-btn-pick" (click)="pickImage(obj)" matTooltip="Bild auswählen" color="primary"><mat-icon>done</mat-icon></button>          
      <button mat-mini-fab class="image-btn-delete" color="basic" [matMenuTriggerFor]="matImageMore" [matMenuTriggerData]="{obj: obj}"><mat-icon>more_vert</mat-icon></button>          
    </mat-card>
    <mat-menu #matImageMore="matMenu">
      <ng-template matMenuContent let-obj="obj">
        <button mat-menu-item (click)="svc.deactivate(obj)">Ausblenden</button>
        <button mat-menu-item (click)="svc.delete(obj)">Löschen</button>
      </ng-template>        
    </mat-menu>
  </div>

  <div fxLayoutAlign="center">

  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <!-- Workaround: nested MatDialog in TinyMCE -->
  <button mat-mini-fab #focusElement (click)="close()"><mat-icon>clear</mat-icon></button>
  <div fxFlex></div>
  <div fxFlex class="align-center">
    <button *ngIf="hasMore" mat-mini-fab (click)="loadNextObjs()" matTooltip="Weitere Bilder laden" color="primary">
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>
  <div fxFlex class="align-right">
    <button *ngIf='true' mat-mini-fab (click)="close()" matTooltip="Abbrechen">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</mat-dialog-actions>
</div>