import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap, map, first } from 'rxjs/operators';
import { ObjDataSource } from 'src/app/shared/obj-data-source';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/admin/shared/config.service';
import { DateFormatShortPipe } from 'src/app/shared/date-format-short.pipe';
import { User, CategoryRegistration, FbUser, UserInternal, Role, UserInfo } from '../shared/user';
import { UserService } from '../shared/user.service';
import { Category } from 'src/app/admin/shared/config';
import { ExcelService } from 'src/app/shared/excel.service';
import { Observable, of } from 'rxjs';
import { DialogService } from 'src/app/shared/dialog.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource: ObjDataSource<UserInfo>;
  public fbUserData: FbUser[] = [];
  public colsDetails: string[] = [ 'lastName', 'firstName', 'email', 'parentalRole', 'categories', 'mailNotify', 'codeOk', 'validated', 'lastLogin', 'actions'];
  public colsNormal: string[] = [ 'lastName', 'firstName', 'email', 'parentalRole', 'categories', 'mailNotify', 'actions'];
  public colsEv: string[] = [ 'lastName', 'firstName', 'email', 'parentalRole', 'categories', 'mailNotify'];
  public cols: string[] = this.colsNormal;
  public pageSize = 10; // for table paginator
  public tags = [];
  public objsLength = 0;

  constructor( public svc: UserService, private router: Router, private configSvc: ConfigService, private dialogSvc: DialogService
             , private dateFormatShort: DateFormatShortPipe, public excelSvc: ExcelService) {
    const objsObs = this.svc.getUserInfoList()
    .pipe(
      // collect tags from objs
      tap( objs => {
        const categoryIds = this.getTagIds(objs);
        this.tags = _.sortBy(categoryIds.map( id => {
          const tag = this.configSvc.getCategory(id) || new Category(id);
          tag['selected'] = false;
          return tag;
        }), c => this.getTagName(c));  
        this.objsLength = objs.length;
      }),
      // sort by name
      map( objs => _.sortBy( objs, obj => obj.user.lastName + obj.user.firstName ))
    );

    // initialize data source 
    this.dataSource = new ObjDataSource(objsObs, this.pageSize);
    this.dataSource.setResetPaginatorCallback( size => {
      if (this.paginator) this.paginator.firstPage();
      this.objsLength = size;
    })

    if (this.showListDetailButton()) this.cols = this.colsNormal
    else this.cols = this.colsEv

    this.dataSource.setFilter( this.getFilterFunc());
  }

  getFbUser(userId: string): Partial<FbUser> {
    return this.fbUserData.find( x => x.id == userId ) || ({});
  }

  getCategoriesText(obj: UserInfo, shortName: boolean = true, joinStr: string = ', '): string {
    const categories = (obj.user.categories ? obj.user.categories.map(c => c.category) : []);
    if (!this.showListDetailButton()) return this.getCategoryNames(categories, shortName).sort().join(joinStr);
    const evCategories = obj.getEvCategories();
    const confirmedEvCategories = obj.getConfirmedEvCategories();
    const unconfirmedEvCategories = _.difference(evCategories,confirmedEvCategories);
    const unmatchedConfirmedEvCategories = _.difference(confirmedEvCategories,evCategories);
    const matchedConfirmedEvCategories = _.intersection(confirmedEvCategories,evCategories);
    const formattedCategories = categories.map( category => {
      const name = this.mapCategoryName(category, shortName);
      if (unconfirmedEvCategories.find( c1 => category == c1 )) return '<span class="redtext">'+name+'</span>';
      if (unmatchedConfirmedEvCategories.find( c1 => category == c1 )) return '<span class="bluetext">'+name+'</span>';
      if (matchedConfirmedEvCategories.find( c1 => category == c1 )) return '<span class="boldtext">'+name+'</span>';
      return name;
    });
    return formattedCategories.join(joinStr);
  }

  getEvCategoriesText(obj: UserInfo, joinStr: string = ', '): string {
    if (!this.svc.userInfo.isAdmin()) return obj.getEvCategories().join(joinStr);
    const evCategories = obj.getEvCategories();
    const confirmedEvCategories = obj.getConfirmedEvCategories();
    const unconfirmedCategories = _.difference(evCategories,confirmedEvCategories)
    const overconfirmedCategories = _.difference(confirmedEvCategories,evCategories)
    const confirmedCategories = _.intersection(confirmedEvCategories,evCategories)
    return unconfirmedCategories.map( c => '<span style="redtext">'+c+'</span>').concat(
      confirmedCategories
    ).concat(
      overconfirmedCategories.map( c => '<span style="bluetext">'+c+'</span>')
    ).join(', ');
  }

  getTagIds(objs: UserInfo[]): string[] {
    return _.chain(objs).map( obj => obj.getCategories())
      .flatten().unique().value()
  }

  getTagName(t: Category): string {
    return t.shortname || t.id
  }

  getCategoryNames( categories: string[], shortName: boolean ): string[] {
    return (categories ? categories.map( category => this.mapCategoryName(category, shortName)) : []);
  }

  mapCategoryName( category: string, shortName: boolean): string {
    if (shortName) {
       return this.configSvc.getCategoryShortname(category)
    } else {
      return category
    }
  }

  ngOnInit() {
  }

  sortChange(sort) {
    this.dataSource.setSort(sort);
  }

  array2String( arr: string[] ) {
    return arr.join(', ');
  }

  selectTag( tag ) {
    tag.selected = !tag.selected;
    this.dataSource.setFilter( this.getFilterFunc());
  }

  tablePageChanged( event: PageEvent ) {
    this.dataSource.setOffset( event.pageIndex * this.pageSize );
  }

  private getFilterFunc(): (obj: UserInfo) => boolean {
    // prepare selected tags
    const selectedTags = this.tags.filter( tag => tag.selected ).map( tag => tag.id );
    // return filter function
    return (obj: UserInfo) => {
      const objTags = obj.getCategories();
      const tagsSelect = _.intersection( objTags, selectedTags ).length === selectedTags.length;
      // filter entries without current registration code
      const activeSelect = this.detailsEnabled() || this.codeOk(obj);
      return tagsSelect && activeSelect;
    };
  }

  showListDetailButton(): boolean {
    return this.svc.userInfo.isAdmin() || this.svc.userInfo.isEditor()
  }

  gotoUser(obj: User) {
    this.router.navigate(['user', obj.id]);
  }

  confirmEv(obj: UserInfo) {
    this.svc.confirmEv(obj);
  }

  updateCode(obj: UserInfo) {
    this.svc.updateCode(obj);
  }

  codeOk(obj: UserInfo) {
    return this.configSvc.isRegistrationCodeHashOk(obj.internal.registrationCodeHash);
  }

  copyEmails(){
    const emails = this.dataSource.getFiltered().map( userInfo => userInfo.user.email ).join("; ");
    const msg = `<p>Mit Copy-Paste können die ausgewählten Emailadressen in ihr Email Programm kopiert werden:</p><p>${emails}</p>`
    this.dialogSvc.openMsgDialog('Emailadressen kopieren', msg);
  }

  exportAsExcel() {
    const userInfos = this.dataSource.getFiltered();
    const usersExt = userInfos.map( userInfo => {
      const exportUser = JSON.parse(JSON.stringify(userInfo.user)); // clone object
      if (userInfo.user.categories) exportUser.categories = userInfo.user.categories.map( r => r.category ).join(','); // convert categories array to string representation
      if (this.detailsEnabled()) {
        const fbUser = this.getFbUser(userInfo.user.id)
        exportUser.emailVerified = fbUser.emailVerified;
        if (fbUser.createdTstmp) exportUser.createdTstmp = this.dateFormatShort.transform(new Date(fbUser.createdTstmp));
        if (fbUser.createdTstmp) exportUser.lastLoginTstmp = this.dateFormatShort.transform(new Date(fbUser.lastLoginTstmp));
        exportUser.disabled = fbUser.disabled;
        exportUser.evCategories = this.getEvCategoriesText(userInfo, ',');
        exportUser.codeOk = this.codeOk(userInfo);
      }
      return exportUser;
    })
    this.excelSvc.exportAsExcelFile(usersExt, "users")
  }

  showDetails() {
    // initialize additional firebase user data
    if (this.svc.userInfo.isAdmin() && this.fbUserData.length==0) {
      this.svc.getFbUserData().pipe(first()).toPromise().then( users => {
        this.fbUserData = users;
      })
    }
    this.cols = this.colsDetails;
    this.dataSource.setFilter( this.getFilterFunc());
  }

  hideDetails() {
    this.cols = this.colsNormal;
    this.dataSource.setFilter( this.getFilterFunc());
  }

  detailsEnabled() {
    return this.cols == this.colsDetails;
  }
}
