<div>
<button mat-button (click)="configSvc.updateCategoriesToDefault()">Update Categories to Default</button>
<br/>
<button mat-button (click)="mediaSvc.fixAllMediaUrls()">Fix Media URL's</button>
<br/>
<button mat-button (click)="importUsers()">Import & Update Users</button>
<br/>
<button mat-button (click)="increaseUsersCategories()">Increase Users Categories</button>
<br/>
<button mat-button (click)="enableNotifications()">Enable Notifications</button>
</div>
<div fxFlex></div>