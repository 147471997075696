import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormatLong'
})
export class DateFormatLongPipe extends DatePipe implements PipeTransform {
  transform(value: Date): any {
    return super.transform(value, 'd MMMM yyyy, HH:mm' );
  }
}
