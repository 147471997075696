<div fxFlex></div>
<div fxFlex="0 1 600px">
<mat-card *ngIf="!userService.isInitialized()" style="max-width:500px">
    <div>Benutzer wird geladen...</div>
</mat-card>
  
<mat-card *ngIf="isNotRegistered()" style="max-width:500px">
    <div>Bitte zuerst mit Registrationscode <a mat-button routerLink='/register'>registrieren</a></div>
</mat-card>
  
<mat-card *ngIf="isSignup()" style="max-width:500px">
  <form [formGroup]="signupForm" fxLayout="column" fxLayoutAlign="start">
      
    <h3>Neuer Benutzer erstellen</h3>

    <div>Oder <a mat-button color="basic" (click)="openLoginDialog()">mit bestehendem Benutzer einloggen?</a></div>

    <mat-form-field style="max-width:300px">
      <input matInput type="email" placeholder="Email Adresse" formControlName="email">
      <mat-error>
        <div *ngIf="email.invalid && email.dirty">Diese Email-Adresse schaut nicht korrekt aus...</div>
      </mat-error>
    </mat-form-field>

    <mat-form-field style="max-width:300px">    
      <input matInput type="password" placeholder="Passwort" formControlName="password">
      <mat-error *ngIf="password.invalid && password.touched">Passwort muss mindestens 6 Zeichen lang sein und eine Ziffer enthalten</mat-error>
    </mat-form-field>

    <mat-form-field style="max-width:300px">    
      <input matInput type="password" placeholder="Passwort wiederholen" formControlName="password2" [errorStateMatcher]="matcher">
      <mat-error *ngIf="signupForm.hasError('notSame')">Passwort stimmt nicht überein!</mat-error>
    </mat-form-field>
  
    <div *ngIf='error' class="errortext">Fehler: {{error}}</div>

    <!--div>Debug: valid={{signupForm.valid}}, dirty={{signupForm.dirty}}, touched={{signupForm.touched}}, password2.invalid={{password2.invalid}}, password2.dirty={{password2.dirty}}, hasError={{signupForm.hasError('notSame')}}</div-->
    <div>
      <button mat-raised-button class="mat-primary" appPreventDoubleClick (throttledClick)='signup()' [disabled]="!signupForm.valid">Erstellen</button>
      <div fxFlex></div>
    </div>
  </form>
</mat-card>
    
<mat-card *ngIf="isDetails()" style="max-width:500px">
  <form #detailForm="ngForm" fxLayout="column" fxLayoutAlign="start">
    <h3>Profil {{userDetail.email}}</h3>

    <div>Bitte geben Sie uns folgende weiteren Informationen bekannt, damit wir Ihre Identität prüfen und Sie korrekt informieren können. </div>
    <br>

    <mat-form-field style="max-width:300px">    
      <input matInput type="text" placeholder="Vorname" required [(ngModel)]="userDetail.firstName" name="firstName">
    </mat-form-field>

    <mat-form-field style="max-width:300px">    
      <input matInput type="text" placeholder="Nachname" required [(ngModel)]="userDetail.lastName" name="lastName">
    </mat-form-field>

    <mat-form-field style="max-width:300px">
      <mat-select placeholder="Rolle" required [(ngModel)]="userDetail.parentalRole" name="parentalRole" (selectionChange)="parentalRoleChanged($event)">
        <mat-option value="father">Vater</mat-option>
        <mat-option value="mother">Mutter</mat-option>
        <mat-option value="other">Betreungsperson</mat-option>
        <mat-option value="interested">Interessiert</mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="userDetail.parentalRole !== 'interested'">
      <div>Bitte tragen Sie hier Ihre Kinder und deren Klassen ein:</div>
      <div class="mat-small">Sind Sie Elternvertreter, bitte bei der entsprechenden Klasse die Checkbox markieren.</div>
    </div>    
    <div *ngIf="userDetail.parentalRole === 'interested'">
      <div>Klassen:</div>
    </div>    
  
    <table mat-table [dataSource]="categoryTblDS" matSort>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name des Kindes</th>
        <td mat-cell *matCellDef="let category; let i = index;">
          <div class="column-input-description">
            <mat-form-field floatLabel="never" class="field-input-description">
              <input matInput placeholder="Name des Kindes" required [(ngModel)]="category.description" name="description_{{i}}" [disabled]="userDetail.parentalRole === 'interested'"/>
            </mat-form-field>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Klasse</th>
        <td mat-cell *matCellDef="let category; let i = index;">
          <div class="column-select-category">
            <mat-form-field floatLabel="never" class="field-select-category">
            <mat-select placeholder="Klasse" required [(ngModel)]="category.category" name="category_{{i}}">
              <mat-option *ngFor="let catOption of categoryOptions;" [value]="catOption.id" 
                          [matTooltip]="catOption.name ? catOption.name : ''">
                {{catOption.shortname || catOption.id}}
              </mat-option>
            </mat-select>
            </mat-form-field>
          </div>  
        </td>
      </ng-container>        
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let category; let i = index;">
          <div class="column-action">
            <mat-checkbox [(ngModel)]="category.representative" name="representative_{{i}}" matTooltip="Elternvertreter?" [disabled]="userDetail.parentalRole === 'interested'"></mat-checkbox>
            <button type="button" (click)="deleteCategory(i)" mat-icon-button matTooltip="Eintrag löschen"><mat-icon>remove_circle_outline</mat-icon></button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="categoryTblCols"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: categoryTblCols"></tr>
    </table>
    <div><button type="button" (click)="addCategory()" mat-icon-button matTooltip="Eintrag hinzufügen"><mat-icon>add_circle_outline</mat-icon></button></div>
    <br/>
    <div>
    <mat-checkbox [(ngModel)]="userDetail.mailNotify" name="mailNotify" class="label-wrap">Ich möchte über für mich relevante Veranstaltungen und Informationen per Email informiert werden.</mat-checkbox>
    </div>
    <!--div>Debug: valid={{detailForm.valid}}, dirty={{detailForm.dirty}}, touched={{detailForm.touched}}, categoryTblDirty={{categoryTblDirty}}</div-->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="button-area">
      <div><button mat-raised-button (click)='cancel()'>Abbrechen</button><div fxFlex></div></div>
      <div fxFlex fxHide.xs="true"></div>
      <div><button mat-raised-button appPreventDoubleClick (throttledClick)='deleteProfile()' class="mat-accent">Profil löschen</button><div fxFlex></div></div>
      <div fxFlex fxHide.xs="true"></div>
      <div><button mat-raised-button appPreventDoubleClick (throttledClick)='setDetails()' class="mat-primary" [disabled]="!detailForm.valid || !detailForm.dirty" style="max-width:100px">Speichern</button><div fxFlex></div></div>
    </div>
  </form>
</mat-card>
</div>
<div fxFlex></div>
