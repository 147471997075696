import * as _ from 'underscore';

export class CategoryRegistration {
    description: string; // Normalerweise mit dem Namen des Kindes befüllt
    category: string; // Klasse des Kindes
    representative = false; // Elternvertreter?
}

export class User {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
    parentalRole?: string;
    mailNotify?: boolean;

    // topics
    categories: CategoryRegistration[];

    checkDetailsExist?() {
        return this.firstName && this.lastName && this.parentalRole;
    }
}

export class FbUser {
    id: string;
    disabled: boolean;
    emailVerified: boolean;
    createdTstmp: number;
    lastLoginTstmp: number;

    constructor( id: string, disabled: boolean, emailVerified: boolean, createdTstmp: number, lastLoginTstmp: number) {
        this.id = id;
        this.disabled = disabled;
        this.emailVerified = emailVerified;
        this.createdTstmp = createdTstmp;
        this.lastLoginTstmp = lastLoginTstmp;
    }
}

export class Role {
    id: string;
    userId?: string;
}

export class UserInternal {
    id: string;
    registrationCodeHash?: string;
    lastCategoryIncrease?: string;
}

export class UserInfo {
    user: User;
    internal: UserInternal;
    roles: string[];
    constructor (user: User, internal: UserInternal, roles: string[]) {
        this.user = user;
        this.internal = internal;
        this.roles = roles;
    }

    getCategories(): string[] {
        const categories = (this.user.categories ? this.user.categories.map( c => c.category ) : []);
        // add categories for vorstand and ev
        if (this.isVorstand()) { categories.push('vorstand'); }
        if (this.isConfirmedEv()) { categories.push('ev'); }
        return categories;
    }

    hasRole(role: string) {
        return this.roles && _.contains(this.roles, role );
    }

    isAdmin() {
        return this.hasRole('admin');
    }

    isEditor() {
        return this.hasRole('editor');
    }

    isVorstand() {
        return this.hasRole('vorstand');
    }

    isDeclaredEv() {
        return this.user && this.user.categories.filter(c => c.representative).length > 0;
    }

    isConfirmedEv() {
        return this.hasRole('ev');
    }

    isConfirmedEvFor(category: string) {
        return this.hasRole('ev' + category);
    }

    getConfirmedEvCategories(): string[] {
        return this.roles && this.roles.filter( role => role.startsWith('ev') && role !== 'ev').map( role => role.substr(2));
    }

    getNormalCategories(): string[] {
        if (!this.user.categories) { return []; }
        return this.user.categories.filter( c => !c.representative ).map( c => c.category);
    }

    getEvCategories(): string[] {
        if (!this.user.categories) { return []; }
        return this.user.categories.filter( c => c.representative ).map( c => c.category);
    }

    getCategoryRegistrations(): CategoryRegistration[] {
        if (!this.user.categories) { return []; }
        return this.user.categories;
    }

    isInterested(categories: string[]) {
        return _.intersection(this.getCategories(), categories).length > 0;
    }
}
