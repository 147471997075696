import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { Config, Category, CategoryPath } from './config';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, shareReplay, tap, filter, delay } from 'rxjs/operators';

import * as _ from 'underscore';
import { defaultConfig, defaultConfigInternal } from './defaultConfig';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private name = 'configs';
  private datePipe: DatePipe;

  collection: AngularFirestoreCollection<Config> = null;
  config: Config;
  configObs: Observable<Config>;
  categoryPaths: CategoryPath[];
  leafCategoryPaths: CategoryPath[];

  constructor(private db: AngularFirestore, @Inject(LOCALE_ID) private locale: string) {

    // init private vars
    this.datePipe = new DatePipe(locale);

    // init collection
    this.collection = db.collection<Config>(this.name);

    // init config and get updates
    this.configObs = this.get('current').pipe(
      filter( config => (config.id ? true : false)), // filter potential empty record
      tap( config => {
        // check if config exists, else init
        if (!config.categories) {
          console.error('categories missing in config!');
          //console.log('initialize configs');
          //db.doc('configs/current').set(defaultConfig).then( x => console.log('configs intialized'));
          //db.doc('configsInternal/current').set(defaultConfigInternal).then( x => console.log('configsInternal intialized'));
        } else {
          this.config = config;
          // prepare category paths
          this.categoryPaths = this.prepareCategoryPaths(config.categories, [])
          .sort( (c1, c2) => {
            // tune sorting: first character between A-Z shall be before numbers
            const name1 = (c1.category.shortname || c1.category.name || c1.category.id).toLowerCase();
            const name2 = (c2.category.shortname || c2.category.name || c1.category.id).toLowerCase();
            const name1StartAZ = name1 > 'a' && name1 < 'z';
            const name2StartAZ = name2 > 'a' && name2 < 'z';
            return (name1StartAZ === name2StartAZ ? (name1 > name2 ? 1 : -1) : (name1StartAZ ? -1 : 1 ));
          });
          this.leafCategoryPaths = this.categoryPaths.filter( cat => cat.category.isLeaf());
          console.log('got config update', config);
          //console.log('leaf categories', this.categoryPaths, this.leafCategoryPaths);
        }
      }),
      shareReplay(1) // cache latest
    );
  }

  prepareCategoryPaths( children: (Category | string)[], path: Category[]): CategoryPath[] {
    // convert config to proper category objects
    const childrenClean =  children.map( obj => {
      if (typeof obj === 'string') {
        return new Category( obj );
      } else {
        return new Category( obj.id, obj.name, obj.shortname, obj.children, obj.hidden );
      }
    });

    // get results from objects having children (not leafs)
    const grandChildrenPathArr = childrenClean.filter( cat => !cat.isLeaf())
    .map( cat => {
      const pathElement = new Category( cat.id, cat.name, cat.shortname ); // clone object without children
      return this.prepareCategoryPaths( cat.children, path.concat([pathElement]));
    });
    // flatten
    const grandChildrenPath = _.flatten(grandChildrenPathArr);

    // create results for each children
    const childrenPath = childrenClean
    .map( cat => {
      return new CategoryPath( cat, path );
    });

    return grandChildrenPath.concat(childrenPath);
  }

  getFirstNonEmptyCategoryLevel(): Category[] {
    // limit to first non empty category level of category tree
    var level = 0;
    var categoryPaths: CategoryPath[];
    do {
      categoryPaths = this.categoryPaths.filter( p => p.path.length == level && !(p.category.hidden || false ))
      level++;
    } while ( categoryPaths.length == 0 && level < 10 )
    return categoryPaths.map( p => p.category);
  }

  expandCategoriesWithParents( categoryNames: string[] ): string[] {
    // search concerned category paths
    const myCategoriesArr = this.categoryPaths
    .filter( cat => _.contains(categoryNames, cat.category.id ))
    // extract parent categories
    .map( cat => cat.path.map( pcat => pcat.id ).concat(cat.category.id));
    // flatten & unique
    return _.chain(myCategoriesArr).flatten().uniq().value();
  }

  expandCategoriesWithChildren( categoryNames: string[] ): string[] {
    // search concerned category paths
    const categoriesArr = this.leafCategoryPaths
    .filter( cat => _.intersection(categoryNames, cat.path.map(c => c.id)))
    // extract child categories
    .map( cat => {
      const catHierarchy = cat.path.concat(cat.category);
      const firstMatchIdx = catHierarchy.findIndex( entry => _.contains(categoryNames, entry.id ));
      if (firstMatchIdx >= 0) {
        return catHierarchy.slice( firstMatchIdx );
      } else {
        return [];
      }
    });
    // flatten & unique
    return _.chain(categoriesArr).flatten().uniq().value().map(c => c.id);
  }  

  getCategory( id: string): Category {
    const path = this.categoryPaths.find( p => p.category.id === id)
    return (path ? path.category : new Category(id));
  }

  getCategoryShortname( id: string ): string {
    const cat = this.getCategory(id);
    return cat.shortname || cat.id;
  }

  isRegistrationCodeHashOk(registrationCodeHash: string) {
    return (registrationCodeHash && registrationCodeHash == this.config.registrationCodeHash);
  }

  // this is uncached!
  get(id: string): Observable<Config> {
    return this.collection.doc<Config>(id).snapshotChanges()
    .pipe(map( obj => ({ id: obj.payload.id, ...obj.payload.data()})));
  }

  update( obj: Partial<Config> ) {
    return this.collection.doc<Config>(obj.id).update(obj);
  }

  updateCategoriesToDefault() {
    this.update( <Partial<Config>>{id: 'current', categories: defaultConfig.categories} )
  }

  formatDateLong( d: any ) {
    return this.datePipe.transform(d, 'd MMMM yyyy, HH:mm');
  }
}
