
export class Article {
    id?: string;
    title: string;
    body: string;
    tstmp: number;
    categories: string[] = [];
    tags: string[] = [];
    active = true;
    public = false;
    imageRefs: string[];
    attachmentRefs: string[] = [];
    type: string; // possible values: event, info
    location: string; // for type=event
    date: number; // for type=event
    exiprationDate: number; // for type=info

    // internal: cache for expanded categories
    expandedCategories?: string[];
}
