import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfigService } from '../admin/shared/config.service';
import { map, tap, first, filter, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MediaService } from '../media/shared/media.service';
import * as _ from 'underscore';
import { UserService } from '../user/shared/user.service';
import { DialogService } from '../shared/dialog.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit, AfterViewInit {

  page: string;
  chapters: Observable<any>;
  expandedState: boolean[] = [];
  forceLogin: boolean = false;
  redirectAfterLogin: string;

  constructor(private route: ActivatedRoute, private router: Router, private config: ConfigService, private cd: ChangeDetectorRef, private mediaSvc: MediaService, public userSvc: UserService, private dialogSvc: DialogService) {
  }

  ngOnInit() {
    this.page = this.route.snapshot.url[0].path;
    this.chapters = this.config.configObs.pipe(
      map( configObj => configObj.blocks[this.page] ),
      tap( chapters => {
        console.log('chapters', chapters)
        // initialize expanded state, default is to expand first panel
        while (this.expandedState.length < chapters.length) {
          this.expandedState.push( this.expandedState.length==0 ? true : false );
        }
      })
    );
    this.route.queryParams.subscribe( params => {
      if (params.hasOwnProperty('login')) {
        console.log("force login");
        this.forceLogin = true;
        this.redirectAfterLogin = params.redirect;
      }
    });
  }

  ngAfterViewInit() {
    // through setTimeout 0 this will be executed after initialization is finished  
    setTimeout(() => {
      if (this.forceLogin) {
        this.userSvc.userInfoObs.pipe(first()).subscribe( user => {
          console.log("got user", user);
          if (!user) { // null if not logged in...
            this.dialogSvc.openLoginDialog().then( result => {
              if (this.redirectAfterLogin && result) this.router.navigate([this.redirectAfterLogin]);
            })
          } else {
            if (this.redirectAfterLogin) this.router.navigate([this.redirectAfterLogin])
          }
        })
      }
    }, 0);
  }

  openedTextPanel(i: number) {
    this.expandedState.forEach( (v, idx) => this.expandedState[idx] = (idx==i ? true : false ))
  }

  getDocuments(selector: string, publicDoc: boolean = true, sortAttr: string = 'name', sortReverse: boolean = false) {
    return this.mediaSvc.getList().pipe(
      first(), 
      map( docs => {
        var selDocs = docs.filter(doc => doc.public == publicDoc && _.contains(doc.tags, selector));
        var sortedDocs = _.sortBy(selDocs, d => d[sortAttr]);
        if (sortReverse) return sortedDocs.reverse();
        else return sortedDocs;
      })
      //tap( docs => console.log("docs", docs))
    )
  }
}
