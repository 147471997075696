import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../shared/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../shared/user';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  loginForm: FormGroup;
  linkLoginForm: FormGroup;
  error = '';
  passwordError = false;
  submitted = false;

  constructor( public fb: FormBuilder, private dialogRef: MatDialogRef<LoginFormComponent>, public userService: UserService
             , public notificationSvc: NotificationService, private routerSvc: Router, private route: ActivatedRoute
             , @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      'email': ['', [Validators.required]],
      'password': ['', [Validators.required]]
    });
    this.linkLoginForm = this.fb.group({
      'email': ['', [Validators.required]]
    });
    this.error = '';
  }

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }
  get linkEmail() { return this.linkLoginForm.get('email'); }

  register() {
    console.log("close dialog");
    this.dialogRef.close(false);
    this.routerSvc.navigate(['/register']);
  }
  
  linkLogin() {
    var email = this.linkEmail.value.trim();
    this.userService.emailLinkLogin(email, location.href)
    .then(creds => {
      this.error = '';
      this.passwordError = false;
      this.dialogRef.close(true);
      //TODO
      console.log("logged in user from link");
      return creds.user.uid;
    })
    .catch( error => {
      console.warn(error);
      this.error = error.message;
      this.submitted = false;
    })
  }  

  login() {
    this.submitted = true;
    this.error = '';
    return this.userService.emailLogin(this.email.value.trim(), this.password.value.trim())
    .then( user => {
      this.error = '';
      this.passwordError = false;
      this.dialogRef.close(true);
      return user.user.uid
    })
    .then( userId => {
      if (this.userService.registrationCodeHash && !this.userService.registrationCodeHashOk) {
        console.log("set registrationCodeHash from login form");
        this.userService.createOrUpdateUserInternal({id: userId, registrationCodeHash: this.userService.registrationCodeHash});
      }
      return userId;
    })
    .catch( error => {
      console.warn(error);
      if (error.code === "auth/wrong-password") {
        this.passwordError = true;
      } else {
        this.error = error.message;
      }
      this.submitted = false;
    });
  }

  resetPassword() {
    const email = this.email.value;
    this.userService.sendPasswordResetEmail(email)
    .then( x => this.notificationSvc.showInfo("Anleitung zum Passwort Reset gesendet an " + email));
  }
}
