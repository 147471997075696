import { Component, OnInit, ViewChild } from '@angular/core';
import { ArticleService } from '../shared/article.service';
import { Article } from '../shared/article';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap, map } from 'rxjs/operators';
import { ObjDataSource } from 'src/app/shared/obj-data-source';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/admin/shared/config.service';
import { DateFormatShortPipe } from 'src/app/shared/date-format-short.pipe';


@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.css']
})
export class ArticlesListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource: ObjDataSource<Article>;
  public cols: string[] = [ 'type', 'title', 'categories', 'tags', 'active', 'public', 'tstmp', 'actions'];
  public pageSize = 10; // for table paginator
  public tags = [];
  public objsLength = 0;

  constructor( private svc: ArticleService, private router: Router, private configSvc: ConfigService
             , private dateFormatShort: DateFormatShortPipe) {
    const objsObs = this.svc.getAllArticles().pipe(
      // collect tags from objs
      tap( articles => {
        const firstLevelCategoryIds = this.configSvc.getFirstNonEmptyCategoryLevel().map( c => c.id )
        const ids = _.sortBy(this.getTagIds(articles).concat(firstLevelCategoryIds), id => id.toLowerCase());
        this.tags = ids.map( id => {
          const tag = this.configSvc.getCategory(id) || {id: id};
          tag['selected'] = false;
          return tag;
        });
        this.objsLength = articles.length;
      }),
      // sort last modified first 
      map( articles => _.sortBy( articles, a => a.tstmp ).reverse())
    );
    this.dataSource = new ObjDataSource(objsObs, this.pageSize);
    this.dataSource.setResetPaginatorCallback( size => {
      if (this.paginator) this.paginator.firstPage();
      this.objsLength = size;
    })
  }

  getTagIds(articles: Article[]): string[] {
    return _.chain(articles)
    .map( article => article.tags )
    .flatten().unique().value();
  }

  getCategoryIds(articles: Article[]): string[] {
    return _.chain(articles)
    .map( article => article.categories )
    .flatten().unique()
    .filter( categoryId => this.configSvc.getCategory(categoryId).children !== undefined)
    .value();
  }

  getCategoryNames( ids: string[] ): string[] {
    return ids.map( id => this.configSvc.getCategoryShortname(id));
  }

  ngOnInit() {
    this.sort.sortChange.subscribe( sort => this.dataSource.setSort(sort));
  }

  array2String( arr: string[] ) {
    return arr.join(', ');
  }

  selectTag( tag ) {
    tag.selected = !tag.selected;
    this.dataSource.setFilter( this.getFilterFunc());
  }

  newObj( id: string ) {
    this.router.navigate(['edit']);
  }

  editObj( id: string ) {
    this.router.navigate(['edit/' + id]);
  }

  cloneObj( article: Article ) {
    this.svc.add( article )
      .then( ref => this.router.navigate(['edit/' + ref.id]));
  }  

  getSummary( obj: Article ) {
    if ( obj.type === 'event') {
      return obj.title + ' / ' + this.dateFormatShort.transform(new Date(obj.date)) + ' / ' + obj.location;
    } else {
        return obj.title;
    }
  }

  tablePageChanged( event: PageEvent ) {
    this.dataSource.setOffset( event.pageIndex * this.pageSize );
  }

  private getFilterFunc(): (obj: Article) => boolean {
    // prepare selected tags
    const selectedTags = this.tags.filter( tag => tag.selected ).map( tag => tag.id );
    // return filter function
    return (obj: Article) => {
      const articleTags = this.svc.getExpandedCategories( obj ).concat(obj.tags);
      return _.intersection( articleTags, selectedTags ).length === selectedTags.length;
    };
  }

  gotoArticle(obj: Article) {
    this.router.navigate(['article', obj.id]);
  }
}
