<h2 mat-dialog-title>Login</h2>

<mat-dialog-content [formGroup]="loginForm" (keydown.enter)="login()" autocomplete="on">
  <div>
    <mat-form-field>    
      <input matInput type="email" placeholder="Email Adresse" formControlName="email" autocomplete="username email">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>    
      <input matInput type="password" placeholder="Passwort" formControlName="password" autocomplete="new-password">
    </mat-form-field>     
  </div>
  <div *ngIf='error' class="errortext">Fehler: {{error}}</div>
  <div *ngIf="passwordError"><span class="errortext">Das Passwort ist falsch.</span><a mat-button color="basic" (click)="resetPassword()">Password zurücksetzen?</a></div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="column">
  <div>
    <button class="mat-raised-button" [mat-dialog-close]="false">Abbrechen</button>
    <div fxFlex></div>
    <button class="mat-raised-button mat-primary" appPreventDoubleClick (throttledClick)="login()" [disabled]="!loginForm.valid || !loginForm.dirty || submitted">Login</button>
  </div>
  <div class="mat-small"><br>Bitte <a mat-button (click)='register()'>registrieren</a> Sie sich zuerst, <br> falls Sie noch kein Benutzerkonto besitzen</div>
</mat-dialog-actions>
