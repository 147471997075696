<mat-expansion-panel expanded hideToggle disabled>

<mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
  <mat-panel-title fxLayout="row" fxLayout.lt-md="column">
    <h3>{{ titleCons }}</h3>
    <div fxFlex></div>
    <div *ngIf="tags.length>0" fxLayoutAlign="end" class="paddingM">
        <span fxFlexAlign="center">Filter&nbsp;</span>
        <mat-chip-list multiple="true" fxFlexAlign="center">
          <mat-chip *ngFor="let t of tags" (click)="selectTag(t)" [selected]="t.selected" [matTooltip]="t.name">{{ t.shortname || t.id }}</mat-chip>
        </mat-chip-list>
    </div>
  </mat-panel-title>
</mat-expansion-panel-header>

<div>
  <div *ngIf="objsObs | async as objs; else nodata" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center">
    <mat-card class="blog-card" *ngFor="let obj of objs" (click)="gotoArticle(obj)">
      <mat-card-content fxLayout="column" fxFill>
        <div>
          <h3 class="card-title">{{ obj.title || 'Titel?' }}</h3>
        </div>
        <div fxFlex>
          <div *ngIf='obj.date'><b>Datum:</b> {{ obj.date | dateFormatShort }}</div>
          <div *ngIf='obj.location'><b>Ort:</b> {{ obj.location }}</div>
        </div>
        <div>
          <div fxFlex></div>
          <div *ngIf='obj.tstmp' class="footer">aktualisiert am {{ obj.tstmp | dateFormatShort }}</div>
        </div>
      </mat-card-content>
    </mat-card>
    <div *ngIf="objs.length==0">Keine Artikel gefunden.</div>
  </div>
  <ng-template #nodata><div fxLayoutAlign="center">Loading...</div></ng-template>


  <div *ngIf='hasMore' fxLayoutAlign="center">
    <button mat-mini-fab (click)="loadNextObjs()" matTooltip="Weitere Artikel laden" color="primary">
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>
</div>
</mat-expansion-panel>