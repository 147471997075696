import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { UserService } from './user/shared/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { DialogService } from './shared/dialog.service';
import { MatSidenav } from '@angular/material/sidenav';
import { AppService } from './shared/app.service';
import { first, filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;

  history: string[] = []

  constructor(private router: Router, public userSvc: UserService, public dialogSvc: DialogService, public appSvc: AppService) {
    this.router.events.subscribe(event => {
      // close sidenav on routing
      this.sidenav.close();
      // remember processed routes
      if (event instanceof NavigationEnd) {
        this.history.push(event.url);
      }
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  logout() {
    this.userSvc.logout();
    this.userSvc.initialized.pipe(filter(x => x == true), first()).toPromise()
    .then( dummy => {
      console.log('Logged out. Navigating to "Home"');
      this.router.navigate(['home']);
    });
  }

  openLoginDialog(stayOnPage: boolean = false) {
    this.dialogSvc.openLoginDialog().then( result => {
      if (result) {
        console.log('Logged in');
        if (!stayOnPage) {
          this.userSvc.initialized.pipe(filter(x => x == true), first()).toPromise()
          .then( x => this.router.navigate(['my']));
        }
      }
      return result;
    });
  }

  isLoggedIn() {
    return this.userSvc.isLoggedIn()
  }

  back() {
    if (this.history.length>0) {
      this.history.pop(); // remove current url
      const lastUrl = this.history.pop(); // get previous url
      this.router.navigateByUrl(lastUrl);
    } else {
      this.router.navigate(['home']);
    }    
  }
}
