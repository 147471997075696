import { Injectable } from '@angular/core';
import { Image } from './image';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, publishReplay, filter, tap, shareReplay } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from 'src/app/user/shared/user.service';
import * as _ from 'underscore';


@Injectable({
  providedIn: 'root'
})
export class ImageService {

  name = 'images';

  collection: AngularFirestoreCollection<Image> = null;

  imagesMap: Map<string, Observable<Image[]>> = new Map();

  // cached list for all objects in specified context
  getContextList(context: string): Observable<Image[]> {
    if (!this.imagesMap.has(context)) {
      const filterFunc = ref => ref.where( 'context', '==', context ).where('active', '==', true);
      this.imagesMap.set( context, this.db.collection<Image>( this.name, filterFunc)
      .snapshotChanges()
      .pipe(
        map( changes => changes.map( obj => ({ id: obj.payload.doc.id, ...obj.payload.doc.data() }))),
        map( objs => _.sortBy(objs, obj => obj.created)),
        tap( objs => console.log(objs)),
        shareReplay(1) // cache all
      ));
    }
    return this.imagesMap.get(context);
  }

  // image Ref formatted as "context/id"
  getByRef( imageRef: string): Observable<Image> {
    const [context, id] = imageRef.split('/');
    return this.getContextList( context ).pipe( map( images => images.find( image => image.id === id )));
  }

  // this is uncached!
  get(id: string): Observable<Image> {
    return this.collection.doc<Image>(id).snapshotChanges()
    .pipe( map( obj => ({ id: obj.payload.id, ...obj.payload.data() } as Image)));
  }

  add( obj: Image ): Promise<DocumentReference> {
    obj.created = new Date();
    obj.owner = this.userService.userInfo.user.id;
    const plainObj = Object.assign({}, obj);
    if (!plainObj.hasOwnProperty('active')) { plainObj['active'] = true; }
    return this.collection.add(plainObj);
  }

  update( obj: Partial<Image> ) {
    obj.updated = new Date();
    this.collection.doc<Image>(obj.id).update(obj);
  }

  upload( file: File, path: string, imageObj: Image ) {
    const fileRef = this.storage.ref( path );
    const task = fileRef.put( file );
    task.then( result => {
      console.log('file uploaded');
      result.ref.getDownloadURL().then( url => {
        // add image if url is ready
        imageObj.url = url;
        this.add( imageObj ).then( r => console.log('image created at url ' + url));
      });
    });
    return task;
  }

  delete( obj: Image ) {
      // physically delete on storage and db
      this.deleteFile( obj.path );
      if (obj.thumbs) {
        obj.thumbs.forEach( (thumbPath) => this.deleteFile(thumbPath));
      }
      this.collection.doc(obj.id).delete();
  }

  deleteFile( path: string ) {
    this.storage.ref(path).delete().toPromise().catch( e => console.log('Error' + e.toString()));
   }

  deactivate( obj: Image ) {
    // deactivate on database
    this.update( {id: obj.id, active: false} );
  }

  createRef( obj: Image ) {
    return `${obj.context}/${obj.id}`;
  }

  constructor(private db: AngularFirestore, private storage: AngularFireStorage, private userService: UserService) {
    this.collection = db.collection<Image>(this.name);
  }
}
