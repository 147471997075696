export class Media {
    name: string;
    path: string;
    url: string;
    type: string; // possible values: image, pdf, file
    tstmp?: number;
    owner?: string;
    tags?: string[];
    active? = true;
    public? = false;
    thumbs?: Map<string, string>;
    id?: string;

    static thumbsReady( obj: Media) {
        return obj.type !== 'image' || obj.thumbs;
    }

    static getThumbUrl( obj: Media, size: string) {
        if (obj.type === 'image') {
            const thumbPath = (obj.thumbs ? obj.thumbs[size] : '');
            return (thumbPath ? obj.url.replace( encodeURIComponent(obj.path), encodeURIComponent(thumbPath)) : '' );    
        } else if (obj.type === 'pdf') {
            return 'assets/pdf.png';
        }
        return 'assets/file.png'; // default
    }

    static getThumbUrlMedium( obj: Media ) {
        const thumbUrl = Media.getThumbUrl( obj, '1024');
        return ( thumbUrl ? thumbUrl : obj.url );
    }

    static getThumbUrlSmall( obj: Media ) {
        const thumbUrl = Media.getThumbUrl( obj, '256');
        return ( thumbUrl ? thumbUrl : Media.getThumbUrlMedium(obj));
    }
}
