import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Article } from './article';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { map, withLatestFrom, switchMap, shareReplay, share, first, filter, tap } from 'rxjs/operators';
import { combineLatest, of} from 'rxjs';
import { UserService } from 'src/app/user/shared/user.service';

import * as _ from 'underscore';
import { ConfigService } from 'src/app/admin/shared/config.service';
import { User, UserInfo } from 'src/app/user/shared/user';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  private name = 'articles';

  collection: AngularFirestoreCollection<Article> = null;
  myCategories: string[] = null;
  myArticles: ReplaySubject<Article[]> = null;
  publicArticles: Observable<Article[]> = null;
  allArticles: Observable<Article[]> = null;

  constructor(private db: AngularFirestore, private userService: UserService, private config: ConfigService) {
    this.collection = db.collection<Article>(this.name);
  }

  getAllArticles(): Observable<Article[]> {
    if (!this.allArticles) {
      return this.db.collection<Article>(this.name).snapshotChanges()
      .pipe(
        map( changes => changes.map(c => ({ id: c.payload.doc.id, ...c.payload.doc.data()} as Article))),
        shareReplay(1)
      );
    }
  }

  getAllTags(): Observable<string[]> {
    return this.getAllArticles().pipe( 
      map(articles => _.chain(articles.map( article => article.tags)).flatten().unique().value())
    );
  }

  getPublicArticles(): Observable<Article[]> {
    if (!this.publicArticles) {
      const filterFunc = ref => ref.where('public', '==', true).where('active', '==', true);
      this.publicArticles = this.db.collection<Article>(this.name, filterFunc).snapshotChanges()
      .pipe(
        map( changes => changes.map(c => ({ id: c.payload.doc.id, ...c.payload.doc.data()} as Article))),
        shareReplay(1)
      );
    }
    return this.publicArticles;
  }

  getMyArticles(): Observable<Article[]> {
    const filterFunc = cat => ref => ref.where('categories', 'array-contains', cat).where('active', '==', true);
                                        // .orderBy('tstmp', 'desc'); // .limit(100); // bug in Firestore -> no results returned...
    if (!this.myArticles) {
      this.myArticles = new ReplaySubject<Article[]>(1);
      this.userService.myCategoriesObs.subscribe( categories => {
        if (this.userService.isLoggedIn()) {
          if (!this.myArticles || !this.myCategories || _.difference(this.myCategories, categories).length > 0 || _.difference(categories, this.myCategories).length > 0) {
            console.log('querying articles for categories', categories);
            this.myCategories = categories;
            const articlesQueries = categories.map(
              cat => this.db.collection<Article>(this.name, filterFunc(cat))
              .snapshotChanges()
              // .pipe( map( changes => { console.log(`got ${changes.length} articles for cat ${cat}`); return changes; })) // debug
            );
            combineLatest(articlesQueries).pipe(
              map( changess => _.flatten(changess)),
              map( changes => _.chain( changes.map(c => ({ id: c.payload.doc.id, ...c.payload.doc.data() } as Article)))
                               .uniq( obj => obj.id ).sortBy( obj => obj.tstmp ).value().reverse())
            )
            .subscribe( articles => this.myArticles.next(articles));
          } else {
            console.log('nothing changed, reuse existing Observable for myArticles',this.myCategories, categories);
          }
        } else { // cant have categories if not logged in...
          console.log('Error: you must be logged in to query "my articles"');
          this.myCategories = null;
          this.myArticles.next([]);
        }
      });
    }
    return this.myArticles;
  }

  getExpandedCategories(article: Article) {
    if (!article.expandedCategories) {
      article.expandedCategories = this.config.expandCategoriesWithParents(article.categories)
      .concat(this.config.expandCategoriesWithChildren(article.categories));
    }
    return article.expandedCategories;
  }

  getExpandedCategoryObjs(article: Article) {
    return this.getExpandedCategories(article).map( c => this.config.getCategory(c)).filter( c => c !== undefined );
  }

  getInterestedUsers(article: Article, userFilterFunc: (u: UserInfo) => Boolean = (u: UserInfo) => true): Observable<User[]> {
    const categories = this.config.expandCategoriesWithChildren(article.categories);
    console.log('expanded categories', categories);
    return this.userService.getUserInfoList()
    .pipe( map( users => {
      const interestedUsers = users
        .filter( user => this.config.isRegistrationCodeHashOk(user.internal.registrationCodeHash))
        .filter( user => userFilterFunc(user))
        .filter( user => user.isInterested(categories))
        .map( user => user.user );
      console.log(`got ${users.length} users, ${interestedUsers.length} are interested`, interestedUsers);
      return interestedUsers;
    }));
  }

  // this is uncached!
  get(id: string): Observable<Article> {
    return this.collection.doc<Article>(id).snapshotChanges()
      .pipe(map( obj => ({ id: obj.payload.id, ...obj.payload.data() } as Article)));
  }

  getCached(id: string): Observable<Article> {
    let articleObs: Observable<Article[]> = null;
    if (this.userService.isLoggedIn() && this.userService.userInfo.isEditor() && this.allArticles) {
      articleObs = this.getAllArticles();
    } else if (this.userService.isLoggedIn() && !this.userService.userInfo.isEditor() && this.myArticles) {
      articleObs = this.getMyArticles();
    } else if (!this.userService.isLoggedIn() && this.publicArticles) {
      articleObs = this.getPublicArticles();
    }
    if (articleObs) {
      return articleObs.pipe( map( objs => {
        const article = objs.find( obj => id === obj.id );
        if (article) {
          return article;
        } else {
          console.log("Artikel nicht gefunden");
          throw new Error('Artikel nicht gefunden');
        }
      }));
    } else {
        return this.get(id).pipe( map( article => {
          if (article.title) {
            return article;
          } else {
            console.log("Artikel nicht gefunden II");
            throw new Error('Artikel nicht gefunden');
          }
        }));
    }
  }

  add( obj: Article ): Promise<DocumentReference> {
    obj.tstmp = Date.now();
    delete obj.id;
    return this.collection.add(Object.assign({}, obj));
  }

  update( obj: Partial<Article> ) {
    obj.tstmp = Date.now();
    return this.collection.doc<Article>(obj.id).update(obj);
  }

  delete( obj: Article ) {
    return this.collection.doc(obj.id).delete();
  }

  deleteById( id: string ) {
    return this.collection.doc(id).delete();
  }
}
