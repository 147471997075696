import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { ImageService } from '../shared/image.service';
import { Image } from '../shared/image';
import { Observable } from 'rxjs';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, tap, finalize, first, single } from 'rxjs/operators';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent implements OnInit {

  // upload vars
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  isHovering: boolean;

  // images vars
  public objs: Observable<Image[]>;
  public limit = -1; // we already have one - the upload card...
  public hasMore = false;

  @ViewChild('focusElement', { static: true }) focusElement: MatButton;

  constructor( public svc: ImageService
             , public dialogRef: MatDialogRef<ImagePickerComponent>
             , @Inject(MAT_DIALOG_DATA) public data: any ) { }
  ngOnInit() {
    console.log('ngOnInit');
    // Workaround: nested MatDialog in TinyMCE
    this.focusElement._elementRef.nativeElement.style.display = 'none';
    this.loadNextObjs();
  }

  setFocus() {
    this.focusElement.focus();
  }

  close() {
    this.dialogRef.close();
  }

  // images
  loadNextObjs() {
    this.objs = this.svc.getContextList( this.data.context )
    .pipe( map( objs => {
      this.hasMore = (objs.length > this.limit);
      return objs;
    }));
    this.limit = this.limit + 10;
  }

  pickImage( obj: Image ) {
    this.dialogRef.close( obj );
  }

  getThumbUrlSmall( obj: Image ) {
    return Image.getThumbUrlSmall(obj);
  }

  // upload
  isActive(pct) {
    return pct < 100;
  }

  startUpload(event: FileList) {
    // The File object
    const file = event.item(0);

    // File type validation
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }

    // Infos zusammenstellen
    const path = `${this.svc.name}/${this.data.context}/${new Date().getTime()}_${file.name}`; // The storage path
    const name = file.name.replace(/\.[^\.].*$/i, ''); // Endung aus Dateiname entfernen

    // start upload
    const image: Image = { name: name, url: 'tmp', path: path, context: this.data.context };
    this.task = this.svc.upload( file, path, image);
    this.percentage = this.task.percentageChanges();
    this.task.task.then( result => this.percentage = null );
  }

}
