import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService} from './user.service';
import { tap, map, take } from 'rxjs/operators';
import { AppService } from 'src/app/shared/app.service';
import { NotificationService } from 'src/app/shared/notification.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private userService: UserService, private notificationSvc: NotificationService) {}

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.userService.initialized.pipe(
      map ( initialized => {
        if (!this.userService.userInfo.isAdmin()) {
          this.notificationSvc.showError( 'Keine Berechtigung - nur für Admin erlaubt' );
          return false;
        } else {
          return true;
        }
    }));
  }
}
