import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleFormComponent } from './articles/article-form/article-form.component';
import { ArticlesBlogComponent } from './articles/articles-blog/articles-blog.component';
import { AdminFormComponent } from './admin/admin-form/admin-form.component';
import { AdminGuard } from './user/shared/admin-guard.service';
import { UserFormComponent } from './user/user-form/user-form.component';
import { RegisterFormComponent } from './user/register-form/register-form.component';
import { PagesComponent } from './pages/pages.component';
import { ArticlesListComponent } from './articles/articles-list/articles-list.component';
import { EditorGuard } from './user/shared/editor-guard.service';
import { ArticleDetailComponent } from './articles/article-detail/article-detail.component';
import { ArticleGuard } from './user/shared/article-guard.service';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { MediaListComponent } from './media/media-list/media-list.component';
import { MediaFormComponent } from './media/media-form/media-form.component';
import { EvGuard } from './user/shared/ev-guard.service';
import { RegistrationGuard } from './user/shared/registration-guard.service';
import { LoginFormComponent } from './user/login-form/login-form.component';

const routes: Routes = [
  { path: 'home', component: PagesComponent, canActivate: [RegistrationGuard] },
//  { path: 'news', component: ArticlesBlogComponent, data: {selector: 'public'}},
  { path: 'my', component: PagesComponent, canActivate: [RegistrationGuard] },
  { path: 'org', component: PagesComponent, canActivate: [RegistrationGuard] },
  { path: 'more', component: PagesComponent, canActivate: [RegistrationGuard] },
  { path: 'edit/:id', component: ArticleFormComponent, canActivate: [RegistrationGuard,ArticleGuard] },
  { path: 'edit', component: ArticleFormComponent, canActivate: [RegistrationGuard,EditorGuard] },
  { path: 'register', component: RegisterFormComponent },
  { path: 'user', component: UserFormComponent, canActivate: [RegistrationGuard] },
  { path: 'user/:id', component: UserFormComponent, canActivate: [RegistrationGuard,AdminGuard] },
  { path: 'article/:id', component: ArticleDetailComponent }, // everyone can see an article if he knows it's id
  { path: 'articles', component: ArticlesListComponent, canActivate: [RegistrationGuard,EditorGuard] },
  { path: 'mediaEdit/:id', component: MediaFormComponent, canActivate: [RegistrationGuard,EditorGuard] },
  { path: 'medias', component: MediaListComponent, canActivate: [RegistrationGuard,EditorGuard] },
  { path: 'admin', component: AdminFormComponent, canActivate: [RegistrationGuard,AdminGuard] },
  { path: 'users', component: UserListComponent, canActivate: [RegistrationGuard,EvGuard] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }  
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
