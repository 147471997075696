import { ErrorHandler, Injectable} from '@angular/core';
import { AppService } from './app.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {

    constructor (private appSvc: AppService) {}

    handleError(error: Error) {
        // send error to the server
        this.appSvc.remoteLog("Error: " + error.message + " (" + error.name + "): " + error.stack.slice(0, 100))
        // And log it to the console
        console.error(error);
    }
}