<mat-card>

  <div *ngIf="dataSource && dataSource.initialized" fxLayoutAlign="center" class="paddingM">
    <span fxFlexAlign="center">Filter&nbsp;</span>
    <mat-chip-list multiple="true">
      <mat-chip *ngFor="let t of tags" (click)="selectTag(t)" [selected]="t.selected" [matTooltip]="t.name">{{ getTagName(t) }}</mat-chip>
    </mat-chip-list>
  </div>

  <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
      <mat-cell *matCellDef="let obj"> {{obj.user.id}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastName"> 
      <mat-header-cell *matHeaderCellDef mat-sort-header> Nachname </mat-header-cell>
      <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{obj.user.lastName}}</div> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="firstName"> 
      <mat-header-cell *matHeaderCellDef mat-sort-header> Vorname </mat-header-cell>
      <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{obj.user.firstName}}</div> </mat-cell>
    </ng-container>  
    <ng-container matColumnDef="email"> 
      <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
      <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{obj.user.email}}</div> </mat-cell>
    </ng-container>  
    <ng-container matColumnDef="parentalRole"> 
      <mat-header-cell *matHeaderCellDef mat-sort-header> Rolle </mat-header-cell>
      <mat-cell *matCellDef="let obj">{{obj.user.parentalRole}}</mat-cell>
    </ng-container>  
    <ng-container matColumnDef="categories">
      <mat-header-cell *matHeaderCellDef> Kategorien{{showListDetailButton() ? '*' : '' }} </mat-header-cell>
      <mat-cell *matCellDef="let obj"> <div class="cell-overflow" [innerHTML]="getCategoriesText(obj)"></div> </mat-cell>
    </ng-container>
    <!--ng-container matColumnDef="elternvertreter">
      <mat-header-cell *matHeaderCellDef mat-sort-header> EV </mat-header-cell>
      <mat-cell *matCellDef="let obj" [innerHTML]="getEvCategoriesText(obj)">
        {{getEvCategoriesText(obj)}}
        <mat-icon *ngIf="getEvStatus(obj)=='check'" [matTooltip]="getEvCategoriesText(obj)">done</mat-icon>
      </mat-cell>
    </ng-container-->
    <ng-container matColumnDef="mailNotify">
      <mat-header-cell *matHeaderCellDef mat-sort-header> B** </mat-header-cell>
      <mat-cell *matCellDef="let obj"><mat-icon *ngIf="obj.user.mailNotify">check</mat-icon></mat-cell>
    </ng-container>
    <ng-container matColumnDef="codeOk">
      <mat-header-cell *matHeaderCellDef mat-sort-header> C** </mat-header-cell>
      <mat-cell *matCellDef="let obj"><mat-icon *ngIf="codeOk(obj)">check</mat-icon></mat-cell>
    </ng-container>
    <ng-container matColumnDef="validated">
      <mat-header-cell *matHeaderCellDef mat-sort-header> V** </mat-header-cell>
      <mat-cell *matCellDef="let obj"><mat-icon *ngIf="getFbUser(obj.user.id).emailVerified">check</mat-icon></mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastLogin">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Letzter Login </mat-header-cell>
      <mat-cell *matCellDef="let obj"> {{getFbUser(obj.user.id).lastLoginTstmp | dateFormatShort}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions" stickyEnd>
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let obj;">
        <button type="button" [matMenuTriggerFor]="matUserMore" [matMenuTriggerData]="{obj: obj}" mat-icon-button><mat-icon>more_vert</mat-icon></button>          
      </mat-cell>
    </ng-container>  
              
    <mat-header-row *matHeaderRowDef="cols"></mat-header-row>
    <mat-row *matRowDef="let row; columns: cols;"></mat-row>
  </mat-table>
  <mat-menu #matUserMore="matMenu">
    <ng-template matMenuContent let-obj="obj">
      <button mat-menu-item (click)="gotoUser(obj.user)">Anzeigen</button>
      <button *ngIf="svc.userInfo.isAdmin() && obj.getEvCategories().length>0" mat-menu-item (click)="confirmEv(obj)">EV bestätigen</button>
      <button *ngIf="svc.userInfo.isAdmin()" mat-menu-item (click)="updateCode(obj)">Code aktualisieren</button>
      <button *ngIf="svc.userInfo.isAdmin()" mat-menu-item [matMenuTriggerFor]="matUserRemoveRoles" [matMenuTriggerData]="{obj: obj}">Role entfernen</button>
      <button *ngIf="svc.userInfo.isAdmin()" mat-menu-item [matMenuTriggerFor]="matUserAddRoles" [matMenuTriggerData]="{obj: obj}">Role hinzufügen</button>
    </ng-template>        
  </mat-menu>

  <mat-menu #matUserRemoveRoles="matMenu">
    <ng-template matMenuContent let-obj="obj">
      <button *ngFor="let role of obj.roles" mat-menu-item (click)="svc.removeRole(obj.user, role)">{{role}}</button>
    </ng-template>
  </mat-menu>    

  <mat-menu #matUserAddRoles="matMenu">
    <ng-template matMenuContent let-obj="obj">
      <button mat-menu-item (click)="svc.addRole(obj.user, 'editor')">Editor</button>
      <button mat-menu-item (click)="svc.addRole(obj.user, 'vorstand')">Vorstand</button>
    </ng-template>
  </mat-menu>    

  <div *ngIf="!dataSource.initialized" fxLayoutAlign="center">Loading...</div>

  <div *ngIf="dataSource.initialized" fxLayoutAlign="center center" id="paginator-container">
    <div *ngIf="showListDetailButton()" class="small-text">* Elternvertreter sind fett markiert, B** Email-Benachrichtigung erwünscht, C** Code aktuell, V** Email verifiziert</div>
    <div *ngIf="!showListDetailButton()" class="small-text">B** Email-Benachrichtigung erwünscht</div>
    <div fxFlex></div>
    <div>
      <mat-paginator *ngIf="objsLength > pageSize" [length]="objsLength" [pageSize]="pageSize" hidePageSize="true" (page)="tablePageChanged($event)" showFirstLastButtons></mat-paginator>    
    </div>
  </div>

  <br/>
  <div fxLayoutGap="10px">
    <div fxFlex></div>
    <button mat-raised-button appPreventDoubleClick (throttledClick)='copyEmails()'>Emailadressen kopieren</button>  
    <button mat-raised-button appPreventDoubleClick (throttledClick)='exportAsExcel()'>Liste exportieren</button>  
    <button mat-raised-button appPreventDoubleClick (throttledClick)='showDetails()' *ngIf="svc.userInfo.isAdmin() && cols!=colsDetails">Details anzeigen</button>  
  </div>
  
</mat-card>
  
