import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../shared/user.service';
import { Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ConfigService } from '../../admin/shared/config.service';
import {Md5} from 'ts-md5/dist/md5';
import { AppService } from 'src/app/shared/app.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css']
})
export class RegisterFormComponent implements OnInit {

  registerForm: FormGroup;

  constructor( public fb: FormBuilder, public userService: UserService, private configService: ConfigService, private appSvc: AppService
             , private router: Router, private cloudFns: AngularFireFunctions, private notificationSvc: NotificationService) { }

  ngOnInit() {
    this.registerForm = this.fb.group({
      'code': ['', [ Validators.required ]]
    });
  }

  get code() { return this.registerForm.get('code'); }

  register() {
    const registrationCodeHash = Md5.hashStr(this.code.value.toUpperCase().trim());
    if (!this.configService.config) {
      const snackbar = this.notificationSvc.showInfo('Warten auf Konfiguration...');      
      this.configService.configObs.pipe(first()).subscribe( config => snackbar.dismiss );
    }
    this.configService.configObs.pipe(first()).subscribe( config => {
      if ( registrationCodeHash === config.registrationCodeHash ) {
        this.notificationSvc.showInfo('Code ok');
        this.userService.setRegistrationCodeHash(registrationCodeHash)
        .then( x => this.router.navigate(['user']));
      } else {
        if (this.appSvc.remoteLogEnabled()) {
          const msgFull = 'Code ist falsch!'+'\nEingegeben: '+this.code.value.toUpperCase()+' Berechneter Hash: '+registrationCodeHash+' Erwarteter Hash: '+this.configService.config.registrationCodeHash+'\nUser Agent: '+window.navigator.userAgent;
          this.notificationSvc.showError(msgFull, true);
        } else {
          this.notificationSvc.showError('Code ist falsch!');
        }
      }
    });
  }
}
