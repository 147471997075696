import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MediaPickerComponent } from '../media/media-picker/media-picker.component';
import { Media } from '../media/shared/media';
import { LoginFormComponent } from '../user/login-form/login-form.component';
import { MsgDialogComponent } from './msg-dialog/msg-dialog.component';
import { TextEntryDialogComponent } from './text-entry-dialog/text-entry-dialog.component';
import { UserService } from '../user/shared/user.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog, private routerSvc: Router, private userSvc: UserService, private notificationSvc: NotificationService) { }

  openMediaPicker( callback: (m: Media) => void, type: String = null ) {
    this.dialog.open(MediaPickerComponent, { hasBackdrop: true, minWidth: '550px', minHeight: '550px', data: { type: type }})
    .afterClosed().subscribe( result => {
      if (result) {
        console.log('Got media', result);
        callback(result);
      }
    });
  }

  openLoginDialog() {
    // open dialog
    return this.dialog.open(LoginFormComponent, { hasBackdrop: true, minWidth: '300px'})
      .afterClosed().toPromise();
  }  

  openMsgDialog(title: String, msg: String) {
    return this.dialog.open(MsgDialogComponent, { data: {title: title, msg: msg}, minWidth: '300px', maxWidth: "75%" })
      .afterClosed().toPromise();
  }

  openTextEntryDialog(title: String, msg: String, submitText: String) {
    return this.dialog.open(TextEntryDialogComponent, { data: {title: title, msg: msg, submitText: submitText}, minWidth: '300px', maxWidth: "75%" })
      .afterClosed().toPromise();
  }

}
