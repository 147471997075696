export class Config {
    id?: string;
    categories?: Category[];
    registrationCodeHash?: string;
    blocks: Map<string, string>;
    baseUrl: string;
    mailPrefix: string;
}

export class Category {
    id: string;
    name?: string;
    shortname?: string;
    children?: (Category | string)[];
    hidden = false;

    constructor( id: string, name?: string, shortname?: string, children?: (Category | string)[], hidden?: boolean) {
        this.id = id;
        if (name) { this.name = name; }
        if (shortname) { this.shortname = shortname; }
        if (children) { this.children = children; }
        if (hidden) { this.hidden = hidden; } else false;
    }

    isLeaf() { return !(this.children && this.children.length > 0); }
}

export class CategoryPath {
    category: Category;
    path: Category[];

    constructor( category: Category, path: Category[]) {
        this.category = category;
        this.path = path;
    }
}
