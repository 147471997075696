import { Directive, HostListener, HostBinding, Output, EventEmitter, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDropZone]'
})
export class DropZoneDirective {

  @Output() dropped =  new EventEmitter<FileList>();

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @Input() dragOverClass: string;

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.dropped.emit($event.dataTransfer.files);
  }

  @HostListener('dragover', ['$event']) onDragOver($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.renderer.addClass(this.el.nativeElement, this.dragOverClass);
  }

  @HostListener('dragleave', ['$event']) onDragLeave($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.renderer.removeClass(this.el.nativeElement, this.dragOverClass);
  }

}
