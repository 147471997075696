import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as Survey from 'survey-angular';
import { SurveyResult } from '../shared/surveyResult';
import { now } from 'underscore';

Survey.StylesManager.applyTheme('modern');

@Component({
  selector: 'surveyjs-component',
  template: `<p *ngIf="previousResult"> Sie haben diese Umfrage bereits am {{ previousResult.tstmp | dateFormatShort }} ausgefüllt. Untenstehend finden sie ihre Antworten. </p><div id="surveyContainer"></div>`,
  styleUrls: ['./surveyjs.component.css']
})
export class SurveyjsComponent  {
  @Input() spec: string;
  @Input() onComplete: (result: SurveyResult) => void;
  @Input() getPreviousResult: () => Promise<SurveyResult>;

  previousResult: SurveyResult;
  result: SurveyResult;

  ngOnInit() {
    this.getPreviousResult()
    .then( result => {
      console.log('got previous result', result);
      var model = new Survey.Model(this.spec);
      // set read-only if previous result
      if (result && result.data) {
        this.previousResult = result;
        model.mode = 'display';
        model.data = result.data;
      }
      model.onComplete.add(this.onCompleteNotifyParent.bind(this)); // bind is needed because otherwise "this" scope is wrong
      Survey.SurveyNG.render('surveyContainer', { model: model });            
    })
  }

  onCompleteNotifyParent(model: Survey.Survey) {
    this.result = { tstmp: now(), data: model.data };
    this.onComplete(this.result);
  }

  hasResult() {
    return this.result != null || this.previousResult != null;
  }
}