import { DataSource } from '@angular/cdk/table';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';

import * as _ from 'underscore';

// DataSource for MatTable, Input is an Observable, implements sorting.
export class ObjDataSource<T> extends DataSource<T> {
    private objs: T[]; // buffer for raw data
    private sort: Sort; // buffer for sort settings
    private resetPaginatorCb: (number) => void;
    private filterFunc: (obj: T) => boolean;
    private offsetFrom = 0;
    private filteredSize = 0;
    private maxSize: number;
    private data = new ReplaySubject<T[]>(1); // Observable with cached values for the table
    public initialized = false;

    constructor(private objsObs: Observable<T[]>, maxSize: number) {
        super();
        objsObs.subscribe( objs => {
            // console.log(`got ${objs.length} records`);
            this.objs = objs;
            this.maxSize = maxSize;
            this.emit();
            this.initialized = true;
        });
    }

    connect(): Observable<T[]> {
        return this.data; // .pipe( tap( objs => console.log('emitted new table data')));
    }

    disconnect() {}

    emit() {
        let data = this.objs;
        this.filteredSize = data.length;
        // apply filter
        if (this.filterFunc) {
            data = data.filter( this.filterFunc );
            this.filteredSize = data.length;
        }
        // apply sort order
        if ( this.sort ) {
            data = _.sortBy( data, obj => obj[this.sort.active]);
            if ( this.sort.direction === 'desc') {
                data = data.reverse();
            }
        }
        // apply offset
        data = data.slice(this.offsetFrom, this.offsetFrom + this.maxSize)
        // console.log(`emit ${data.length} records`);
        // emit next value to observable
        this.data.next( data );
    }

    setSort(sort: Sort) {
        if (sort.direction) {
            this.sort = sort;
        } else {
            this.sort = null;
        }
        this.setOffset(0); // this is also calling emit();
        if (this.resetPaginatorCb) this.resetPaginatorCb(this.filteredSize);
    }

    setResetPaginatorCallback(resetCb: (number) => void) {
        this.resetPaginatorCb = resetCb;
    }

    setFilter( filterFunc: (obj: T) => boolean) {
        this.filterFunc = filterFunc;
        this.setOffset(0); // this is also calling emit();
        if (this.resetPaginatorCb) this.resetPaginatorCb(this.filteredSize);
    }

    setOffset( offsetFrom: number) {
        this.offsetFrom = offsetFrom;
        if (this.initialized) this.emit();
    }

    getAll() {
        return this.objs;
    }

    getFiltered() {
        let data = this.objs;
        if (this.filterFunc) {
            data = data.filter( this.filterFunc );
        }
        return data;
    }    
}
