<div *ngIf="chapters | async; else nodata" class="page">
    <mat-accordion multi="true">
        <div *ngFor="let chapter of chapters | async; index as i">
            <!-- type text -->
            <mat-expansion-panel *ngIf="!chapter.type || chapter.type==='text'" [expanded]="expandedState[i]" (opened)="openedTextPanel(i)">
                <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
                    <mat-panel-title><h3>{{ chapter.title }}</h3></mat-panel-title>
                </mat-expansion-panel-header>
                <div [innerHtml]="chapter.content | sanitize"></div>
            </mat-expansion-panel>

            <!-- type blog -->
            <app-articles-blog *ngIf="chapter.type==='blog'" app-articles-blog [title]="chapter.title" [selector]="chapter.selector"></app-articles-blog>

            <!-- type documents -->
            <mat-expansion-panel *ngIf="chapter.type==='docs'" [expanded]="expandedState[i]" (opened)="openedTextPanel(i)">
                <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
                    <mat-panel-title><h3>{{ chapter.title }}</h3></mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <ul *ngIf="chapter.public || userSvc.isLoggedIn()"><li *ngFor="let d of getDocuments(chapter.selector, chapter.public, chapter.sortAttr ?? 'name', chapter.reverse || false) | async"><a [href]="d.url">{{d.name}}</a></li></ul>
                    <div *ngIf="!chapter.public && !userSvc.isLoggedIn()">Bitte <a (click)="app.openLoginDialog(true)" class="link">loggen sie sich ein</a>, um nicht öffentliche Dokumente zu sehen.</div>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>
</div>

<ng-template #nodata><div fxLayoutAlign="center center" id="loading">Loading...</div></ng-template>
