export const defaultConfig = {
    id: 'current',
    baseUrl: 'test.ef3047.ch',
    mailPrefix: '[Elternforum !TEST!]',

    blocks: {
        home: [
            {
                title: 'Willkommen beim Elternforum',
                content: '<p>Willkommen beim Elternforum von Bremgarten bei Bern. Das Elternforum...</p> <ul> <li>schafft eine Plattform für den Austausch von Informationen zwischen der Schulleitung, den Eltern, der Bildungskommission, der Jugendarbeit und weiteren interessierten Personen</li> <li>setzt sich für eine konstruktive, vertrauensvolle und offene Zusammenarbeit zwischen den Eltern und der Schulleitung/Lehrpersonen ein</li> <li>vertritt keine Einzelinteressen von Eltern und Kindern, ist konfessionell sowie politisch neutral und arbeitet ehrenamtlich</li> </ul>'
            },
            {
                title: 'Allgemeine Veranstaltungen und Informationen',
                type: 'blog',
                selector: 'public'
            }
        ],
        org: [
            {
                title: 'Elternarbeit',
                content: '<h4>Elternvertretung werden</h4> <p>Als Elternvertretung Ihrer Klasse sind Sie Bindeglied zwischen der Lehrperson, den Eltern und dem Elternforum. Sie nehmen am Runden Tisch Ihrer Stufe teil (zwei Termine pro Schuljahr) und kommunizieren wenn nötig mit den Eltern der Klasse, welche Sie vertreten.</p> <h4>Teilnahme am Runden Tisch</h4> <p>Sie nehmen als interessierte Eltern am Runden Tisch teil und können direkt mit den Schulleitungen und anderen Eltern Themen diskutieren, die Sie bewegen.</p> <h4>Ausschuss</h4> <p>Im Ausschuss des Elternforums engagieren Sie sich im Hintergrund (administrative und organisatorische Arbeiten) oder als Stufenverantwortliche am Runden Tisch. Sie arbeiten eng mit den Schulleitungen zusammen und nehmen an den Runden Tischen Ihrer Stufe und den Ausschusssitzungen teil (ca. 7 Termine im Jahr). Eine Vertretung des Ausschusses nimmt in der Bildungskommission mit beratender Stimme und Antragsrecht, aber ohne Stimmrecht, Einsitz.</p>'
            },
            {
                title: 'Runde Tische',
                content: '<h4>Themen</h4> <p>Zur Evaluation der Themen für die Runden Tische werden vorgängig Vorschläge und Ideen in den Klassen gesammelt. Wenn keine Themen vorhanden sind und der Austausch nicht gewünscht wird, erfolgt eine Absage des Termins für diesen Runden Tisch. Damit wird gewährleistet, dass man nur Zeit investiert, wenn es einem Bedürfnis entspricht.</p> <h4>Stufenübergreifende Gespräche am Runden Tisch</h4> <p>Die Themen der letzten Jahre haben gezeigt, dass ein Interesse besteht, die bisher stufengetrennten Gespräche am Runden Tisch zusammenzuführen. Ab dem Schuljahr 2019/2020 wird der Kindergarten einen, die Unter- und Oberstufe je zwei Runde Tische durchführen. Der Austausch zwischen den Eltern wird durch stufenübergreifende Gespräche bereichert.</p> <h4>Teilnehmende</h4> <p>Die Gespräche am Runden Tisch stehen allen Personen offen. Insbesondere sind auch Eltern ohne offizielles Amt herzlich eingeladen mitzudiskutieren und mitzugestalten.</p> <p>Aufgrund ihrer Funktion im Elternforum nehmen teil:</p> <ul> <li>die Schulleitung der jeweiligen Stufe</li> <li>die Elternvertretungen der jeweiligen Stufe</li> <li>Vertretende der Schulsozialarbeit, des Lehrkörpers oder externe Fachkräfte (je nach Thema und Bedarf)</li> <li>die Stufenverantwortliche des Ausschusses des Elternforums</li> </ul> <h4>Wir sind auf Ihre Mitarbeit angewiesen</h4> <p>Teilen Sie uns Ihre Anliegen und Wünsche für die Runden Tische mit. Nehmen, Sie an den Runden Tischen teil, dies ist eine einmalige Gelegenheit, sich mit anderen Eltern und den Schulleitungen auszutauschen</p> <p>Gerne können Sie sich auch direkt an das Elternforum info@ef3047.ch wenden. Wir freuen uns.</p>'
            }
        ],
        more: [
            {
                title: 'Kontakt',
                content: '<h4>Genereller Kontakt</h4> <ul> <li>Mail Elternforum: <b><a href="mailto:info@ef3047.ch">info@ef3047.ch</a></b> </li> </ul> <h4>Organisation</h4> <p> <ul> <li>Präsidium: Andrea Reinhard</li> <li>Stv. Präsidium: Sabine Camenisch</li> <li>Sekretariat / Finanzen: Ursula Weber Zbinden</li> <li>Webseite: Zach Kull</li> </ul> </p>'
            },
            {
                title: 'Links',
                content: '<ul> <li><a href="http://www.3047.ch">Gemeinde</a></li> <li><a href="https://www.ostbremgarten.ch">Oberstufe</a></li> <li><a href="http://www.jawohl.ch">Jugendarbeit Wohlen</a></li> <li><a href="http://www.erz.be.ch">Erziehungsdirektion Bern</a></li> </ul>' 
            },
            {
                title: 'Dokumente',
                content: '<p>coming soon...</p>'
            }
        ],
        my: [
            {
                title: 'Meine Veranstaltungen und Informationen', 
                type: 'blog',
                selector: 'my'
            }
        ]
    },

    categories: [
        {
            'id': 'all',
            'name': 'Alle',
            'shortname': 'Alle',
            'hidden': true,
            'children': [
                {
                    'id': 'ost',
                    'name': 'Oberstufe',
                    'shortname': 'OST',
                    'children': [
                        {
                            'id': '5kl',
                            'name': '5.Klassen',
                            'shortname': '5.Kl',
                            'hidden': true,
                            'children': [
                                '5a',
                                '5b',
                                '5c'        
                            ]              
                        },
                        {
                            'id': '6kl',
                            'name': '6.Klassen',
                            'shortname': '6.Kl',
                            'hidden': true,
                            'children': [
                                '6a',
                                '6b'
                            ]              
                        },
                        {
                            'id': '7kl',
                            'name': '7.Klassen',
                            'shortname': '7.Kl',
                            'hidden': true,
                            'children': [
                                '7a',
                                '7b'
                            ]              
                        },
                        {
                            'id': '8kl',
                            'name': '8.Klassen',
                            'shortname': '8.Kl',
                            'hidden': true,
                            'children': [
                                '8a',
                                '8b'
                            ]              
                        },
                        {
                            'id': '9kl',
                            'name': '9.Klassen',
                            'shortname': '9.Kl',
                            'hidden': true,
                            'children': [
                                '9a'
                            ]              
                        }
                    ],
                },
                {
                    'id': 'ust',
                    'name': 'Unterstufe',
                    'shortname': 'UST',    
                    'children': [
                        {
                            'id': '1kl',
                            'name': '1.Klassen',
                            'shortname': '1.Kl',
                            'hidden': true,
                            'children': [
                                '1a',
                                '1b',
                                '1c'        
                            ]              
                        },
                        {
                            'id': '2kl',
                            'name': '2.Klassen',
                            'shortname': '2.Kl',
                            'hidden': true,
                            'children': [
                                '2a',
                                '2b',
                                '2c'        
                            ]              
                        },
                        {
                            'id': '3kl',
                            'name': '3.Klassen',
                            'shortname': '3.Kl',
                            'hidden': true,
                            'children': [
                                '3a',
                                '3b',
                                '3c'        
                            ]              
                        },
                        {
                            'id': '4kl',
                            'name': '4.Klasse',
                            'shortname': '4.Kl',
                            'hidden': true,
                            'children': [
                                '4a',
                                '4b',
                                '4c'        
                            ]              
                        }
                    ]
                },
                {
                    'id': 'kiga',
                    'name': 'Kindergarten',
                    'shortname': 'KiGa',        
                    'children': [
                        {
                        'id': 'kg1',
                        'name': 'KG Äschenbrunnmattstrasse 6',
                        'shortname': 'KG Ä.b.m.str 6'
                        },
                        {
                        'id': 'kg2',
                        'name': 'KG Kalchackerhof I',
                        'shortname': 'KG K.hof I'
                        },
                        {
                        'id': 'kg3',
                        'name': 'Kalchackerhof II',
                        'shortname': 'KG K.hof II'
                        },
                        {
                        'id': 'kg4',
                        'name': 'KG Freudenreichstrasse 3',
                        'shortname': 'KG F.r.str 3'
                        },
                        {
                        'id': 'kg5',
                        'name': 'KG Johanniterstrasse 24',
                        'shortname': 'KG J.str 24'
                        }
                    ]
                },
                {
                    'id': 'ev',
                    'name': 'Elternvertreter',
                    'shortname': 'EV'
                },
                {
                    'id': 'vorstand',
                    'name': 'Vorstand',
                    'shortname': 'Vorstand'
                }                
            ]
        }
    ],
};

export const defaultConfigInternal = {
    id: 'current',
    registrationCode: 'abcdef'
};
