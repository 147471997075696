import { Injectable } from '@angular/core';
import { Media } from './media';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable, ReplaySubject } from 'rxjs';
import { map, publishReplay, filter, tap, shareReplay, distinctUntilChanged, first } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from 'src/app/user/shared/user.service';
import * as _ from 'underscore';


@Injectable({
  providedIn: 'root'
})
export class MediaService {

  name = 'medias';

  collection: AngularFirestoreCollection<Media> = null;

  medias: ReplaySubject<Media[]> = null;

  constructor(private db: AngularFirestore, private storage: AngularFireStorage, private userService: UserService) {
    this.collection = db.collection<Media>(this.name);
  }

  // cached list for all objects
  getList(): Observable<Media[]> {
    if (!this.medias) {
      this.medias = new ReplaySubject<Media[]>(1);
      // reset medias observer if user login/logout
      this.userService.userInfoObs.pipe(
        map( user => user !== null && user !== undefined ),
        distinctUntilChanged()
      ).subscribe( loggedIn => {
        const filterFunc = ref => {
          if (loggedIn) {
            return ref.where('active', '==', true);
          } else {
            return ref.where('active', '==', true).where('public', '==', true);
          }
        }
        this.db.collection<Media>( this.name, filterFunc)
        .snapshotChanges()
        .pipe(
          (!this.userService.userInfo || !this.userService.userInfo.isEditor() ? first() : tap(x => x)), // editors need updates on the document list
          map( changes => changes.map( obj => ({ id: obj.payload.doc.id, ...obj.payload.doc.data() }))),
          map( objs => _.sortBy(objs, obj => obj.tstmp)),
        ).subscribe( medias => this.medias.next(medias));
      });
    }
    return this.medias;
  }

  getCachedAll( ids: string[] ): Observable<Media[]> {
    return this.getList().pipe( map( objs => objs.filter( obj => _.contains( ids, obj.id ))), distinctUntilChanged());
  }

  getCached( id: string): Observable<Media> {
    return this.getList().pipe( map( objs => objs.find( obj => obj.id == id )));
  }

  // this is uncached!
  get(id: string): Observable<Media> {
    return this.collection.doc<Media>(id).snapshotChanges()
    .pipe( map( obj => ({ id: obj.payload.id, ...obj.payload.data() } as Media)));
  }

  getAllTags(): Observable<string[]> {
    return this.getList().pipe( 
      map(objs => _.chain(objs.map( obj => obj.tags)).filter( tag => tag != null).flatten().unique().value())
    );
  }

  add( obj: Media ): Promise<DocumentReference> {
    obj.tstmp = Date.now();
    obj.owner = this.userService.userInfo.user.id;
    const plainObj = Object.assign({}, obj);
    if (!plainObj.hasOwnProperty('active')) { plainObj['active'] = true; }
    return this.collection.add(plainObj);
  }

  update( obj: Partial<Media> ) {
    obj.tstmp = Date.now();
    return this.collection.doc<Media>(obj.id).update(obj);
  }

  upload( file: File, path: string, obj: Media ) {
    const fileRef = this.storage.ref( path );
    const task = fileRef.put( file );
    task.then( result => {
      console.log('file uploaded');
      result.ref.getDownloadURL().then( url => {
        // add image if url is ready
        obj.url = url;
        this.add( obj ).then( r => console.log('image created at url ' + url, r));
      });
    });
    return task;
  }

  // after copying between different buckets, url must be updated
  // fix by getting the new url for the same path from storage
  fixMediaUrl( obj: Media ) {
    const fileRef = this.storage.ref( obj.path );
    fileRef.getDownloadURL().subscribe( url => {
      if ( !url ) {
        console.log( "url not found for " + obj.name + " (" + obj.id + ")" );
      } else if ( url != obj.url) {
        console.log( "updating url for " + obj.name + " (" + obj.id + ")", obj.url, url );
        this.update( {id: obj.id, url: url});
      }
    })
  }

  fixAllMediaUrls() {
    this.getList().pipe(first())
    .forEach( medias => medias.forEach( media => this.fixMediaUrl(media)));
  }

  delete( obj: Media ) {
      // physically delete on storage and db
      this.deleteFile( obj.path );
      if (obj.thumbs) {
        console.log('thumbs', obj.thumbs);
        Object.keys(obj.thumbs).map( (thumbKey) => this.deleteFile(obj.thumbs[thumbKey]));
      }
      this.collection.doc(obj.id).delete();
  }

  deleteFile( path: string ) {
    this.storage.ref(path).delete().toPromise().catch( e => console.log('Error' + e.toString()));
   }

  deactivate( obj: Media ) {
    // deactivate on database
    this.update( {id: obj.id, active: false} );
  }
}
