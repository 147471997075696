import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit, AfterContentInit, NgZone } from '@angular/core';
import { MediaService } from '../shared/media.service';
import { Media } from '../shared/media';
import { Observable } from 'rxjs';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { map, tap, finalize, first, single, shareReplay, publishReplay } from 'rxjs/operators';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { ObjDataSource } from 'src/app/shared/obj-data-source';
import * as _ from 'underscore';

@Component({
  selector: 'app-media-picker',
  templateUrl: './media-picker.component.html',
  styleUrls: ['./media-picker.component.scss']
})
export class MediaPickerComponent implements OnInit {

  // upload vars
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  isHovering: boolean;

  // collection vars
  public objs: Observable<Media[]>;
  public tablePageSize = 7; // table paginator
  public cardsLineSize = 5; // increment for showing more cards
  public limit = 3 * this.cardsLineSize -1; // we already have one - the upload card...
  public hasMore = false;
  public objsLength = 0;
  public renderAsList = true;

  // table vars
  public dataSource: ObjDataSource<Media>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public cols: string[] = [ 'type', 'name', 'updated' /*, 'owner', 'active', 'tags', 'thumbs'*/, 'actions'];

  public tags = [];

  constructor( public svc: MediaService
             , public dialogRef: MatDialogRef<MediaPickerComponent>
             , @Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnInit() {
    console.log('MediaPickerComponent ngOnInit');
    // init data
    var type = this.data.type
    this.objs = this.svc.getList()
    // collect tags from objs
    .pipe(
      map( objs => _.filter(objs, obj => type==null || obj.type == type)),
      map( objs => _.sortBy(objs, obj => obj['updated']).reverse()),
      tap( objs => {
        const tagNames = _.chain(objs).map( obj => obj.tags ).filter( tag => tag != null)
                          .flatten().unique().value();
        console.log(tagNames);
        this.tags = tagNames.map( tagName => {
          return {name: tagName, selected: true };
        });
        this.hasMore = (objs.length > this.limit);
        this.objsLength = objs.length;
      })
    );

    this.dataSource = new ObjDataSource(this.objs, this.tablePageSize);
    this.sort.sortChange.subscribe( sort => this.dataSource.setSort(sort));
    this.setDefaultSort();

    // this is a hack -> but the table isn't showing data after initialization otherwise...
    setTimeout(() => this.dataSource.emit(), 1);
  }

  setRenderAsList( renderAsList: boolean ) {
    this.renderAsList = renderAsList;
  }

  setDefaultSort() {
    this.dataSource.setSort({active: 'tstmp', direction: 'desc'});
  }

  close() {
    this.dialogRef.close();
  }

  loadNextObjs() {
    this.limit = this.limit + this.cardsLineSize;
  }

  tablePageChanged( event: PageEvent ) {
    this.dataSource.setOffset( event.pageIndex * this.tablePageSize );
  }

  selectTag( tag ) {
    tag.selected = !tag.selected;
    this.dataSource.setFilter( this.getFilterFunc());
  }

  pickMedia( obj: Media ) {
    console.log('pickMedia', obj);
    this.dialogRef.close( obj );
  }

  thumbsReady( obj: Media ) {
    return Media.thumbsReady(obj);
  }

  getThumbUrlSmall( obj: Media ) {
    return Media.getThumbUrlSmall(obj);
  }


  // upload
  isActive(pct) {
    return pct < 100;
  }

  startUpload(event: FileList) {
    // The File object
    const file = event.item(0);

    // File type validation
    const fileType = file.type.split('/');
    let mediaType = '';
    if (fileType[0] === 'image') {
      mediaType = 'image';
    } else if (fileType[0] === 'application' && fileType[1] === 'pdf' ) {
      mediaType = 'pdf';
    } else {
      console.error('unsupported file type', file.type);
      return;
    }

    // Infos zusammenstellen
    const path = `${this.svc.name}/${new Date().getTime()}_${file.name}`; // The storage path
    const name = file.name.replace(/\.[^\.]*$/i, ''); // Endung aus Dateiname entfernen

    // start upload
    const media: Media = { name: name, url: 'tmp', path: path, type: mediaType, active: true, public: true };
    this.task = this.svc.upload( file, path, media);
    this.percentage = this.task.percentageChanges();
    this.task.task.then( result => this.percentage = null );
    this.setDefaultSort();
  }


  // create tag filter function
  private getFilterFunc(): (obj: Media) => boolean {
    // prepare selected tags
    const selectedTags = this.tags.filter( tag => tag.selected ).map( tag => tag.name );
    // return filter function
    if (selectedTags.length === this.tags.length) { // alle tags sind selektiert
      return (obj: Media) => true;
    } else {
      return (obj: Media) => {
        return _.intersection( obj.tags, selectedTags ).length > 0 || _.contains( selectedTags, obj.type);
      };
    }
  }

}
