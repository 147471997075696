export class Recipient {
    userKey: string;
    email: string;
    constructor(userKey: string, email: string) {
        this.userKey = userKey;
        this.email = email;
    }
}

export class Mailjob {
    id: string;
    recipients: Recipient[];
    title: string;
    content: string;
    contentKey: string;
    status: string; // prepared, sent, error
    created?: Date;
    updated?: Date;
    owner?: string;
}
