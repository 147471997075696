<mat-card appDisableDrop>

  <div fxLayoutAlign="center" class="paddingM" *ngIf="tags.length > 0">
    <span fxFlexAlign="center">Filter&nbsp;</span>
    <mat-chip-list multiple="true">
      <mat-chip *ngFor="let t of tags" (click)="selectTag(t)" [selected]="t.selected" [matTooltip]="t.name">{{ t.shortname || t.id }}</mat-chip>
    </mat-chip-list>
  </div>

  <mat-table [dataSource]="dataSource" matSort (dropped)="startUpload($event)" appDropZone dragOverClass="mat-elevation-z6">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
      <mat-cell *matCellDef="let obj"> {{obj.id}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="type"> 
      <mat-header-cell *matHeaderCellDef mat-sort-header> Typ </mat-header-cell>
      <mat-cell *matCellDef="let obj"> {{obj.type}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Name </mat-header-cell>
      <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{obj.name}}</div> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="updated">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Änderungszeit </mat-header-cell>
      <mat-cell *matCellDef="let obj"> {{obj.tstmp | dateFormatShort}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="owner">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Ersteller </mat-header-cell>
      <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{userSvc.getShortName(obj.owner) | async}}</div> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Aktiv </mat-header-cell>
      <mat-cell *matCellDef="let obj"><mat-icon *ngIf="obj.active">check</mat-icon></mat-cell>
    </ng-container>
    <ng-container matColumnDef="public">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Public </mat-header-cell>
      <mat-cell *matCellDef="let obj"><mat-icon *ngIf="obj.public">check</mat-icon></mat-cell>
    </ng-container>
    <ng-container matColumnDef="tags">
      <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
      <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{array2Str(obj.tags)}}</div> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="thumbs">
      <mat-header-cell *matHeaderCellDef> Thumbs </mat-header-cell>
      <mat-cell *matCellDef="let obj"><mat-icon *ngIf="obj.thumbs" [matTooltip]="mapkeys2Str(obj.thumbs)">check</mat-icon></mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions" stickyEnd>
      <mat-header-cell *matHeaderCellDef>
        <div class="mat-icon-button">
          <mat-icon *ngIf="!percentage" mat-icon-button dragOverClass="mat-elevation-z6" matTooltip="Zum hochladen die neue Datei hierherziehen">add_circle_outline</mat-icon>
          <mat-progress-spinner *ngIf="percentage" mode="determinate" diameter="24" [value]="percentage | async" (click)="task.cancel()" matTooltip="Zum Abbrechen hier drücken"></mat-progress-spinner>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let obj;">
        <button type="button" [matMenuTriggerFor]="matUserMore" [matMenuTriggerData]="{obj: obj}" mat-icon-button><mat-icon>more_vert</mat-icon></button>          
      </mat-cell>
    </ng-container>  

    <mat-menu #matUserMore="matMenu">
      <ng-template matMenuContent let-obj="obj">
        <button mat-menu-item (click)="editObj(obj.id)">Edit</button>
        <button mat-menu-item (click)="svc.delete(obj)">Delete</button>
      </ng-template>        
    </mat-menu>
                
    <mat-header-row *matHeaderRowDef="cols"></mat-header-row>
    <mat-row *matRowDef="let obj; columns: cols;"></mat-row>
  </mat-table>
  <mat-paginator *ngIf="objsLength > pageSize" [length]="objsLength" [pageSize]="pageSize" hidePageSize="true" (page)="tablePageChanged($event)" showFirstLastButtons></mat-paginator>    

</mat-card>