import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService} from './user.service';
import { tap, map } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/notification.service';

@Injectable()
export class RegistrationGuard implements CanActivate {
  constructor(private userService: UserService, private notificationSvc: NotificationService, private router: Router) {}

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.userService.initialized.pipe(
      map ( initialized => {
        if (this.userService.isLoggedIn() && !this.userService.registrationCodeHashOk) {
          this.notificationSvc.showError( 'Registration Code ist nicht mehr aktuell' );
          this.router.navigate(['register']);
          return false;
        } else {
          return true;
        }
    }));
  }
}
