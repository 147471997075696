<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content>
  <div *ngIf="data.msg" [innerHTML]="data.msg"></div>
  <textarea cdkTextareaAutosize cdkAutosizeMinRows="5" [(ngModel)]="data.text"></textarea>
  <br>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">Abbrechen</button>
  <div fxFlex></div>
  <button class="mat-raised-button mat-primary" [mat-dialog-close]="data.text">{{data.submitText}}</button>
</mat-dialog-actions>
