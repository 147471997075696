// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: { // ef3047-test
    apiKey: "AIzaSyDoW933sk_nd9iZOcghPW6JMRRLo0OWGKE",
    authDomain: "ef3047-test.firebaseapp.com",
    databaseURL: "https://ef3047-test.firebaseio.com",
    projectId: "ef3047-test",
    storageBucket: "ef3047-test.appspot.com",
    messagingSenderId: "528476497224",
    appId: "1:528476497224:web:f310d2ba55ea36bd"
  },
  remoteLogging: false,
  adminEmail: "admin@ef3047.ch",
  infoEmail: "admin@ef3047.ch"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
