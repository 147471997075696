import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MediaService } from '../shared/media.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/admin/shared/config.service';
import { AppService } from 'src/app/shared/app.service';
import { tap, startWith, map, first } from 'rxjs/operators';
import { waitFor } from 'src/app/shared/helpers';
import { Media } from '../shared/media';
import * as _ from 'underscore';
import { AppComponent } from 'src/app/app.component';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-media-form',
  templateUrl: './media-form.component.html',
  styleUrls: ['./media-form.component.css']
})
export class MediaFormComponent implements OnInit {


  @ViewChild('form') public form: NgForm;
  obj: Media;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagAuto') tagAuto: MatAutocomplete;
  tagCtrl = new FormControl();
  allTags: string[] = [];
  filteredTags: Observable<string[]>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  chipsDirty = false;

  constructor(private svc: MediaService, private route: ActivatedRoute, private configSvc: ConfigService, private appSvc: AppService, public dialog: MatDialog, private app: AppComponent, private notificationSvc: NotificationService ) {
    // dynamically filter values for tags autocomplete
    const tagsObs = this.svc.getAllTags()
    .pipe( tap( tags => this.allTags = tags));
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      waitFor(tagsObs),
      map((tag: string | null) => tag ? this._filterTags(tag) : this.allTags.slice())
    );
  }

  ngOnInit() {

    // init article
    this.route.params.subscribe( params => {
      if (params['id']) {
        console.log('query id', params['id']);
        this.svc.getCached(params['id']).pipe(first()).subscribe( obj => {
          this.obj = obj;
          if (!this.obj.tags) this.obj.tags = [];
          console.log('edit media', this.obj);
        });
      } else {
        this.obj = new Media();
      }
    });
  }

  mapkeys2Str( map: Map<string, string> ) {
    return (map? _.keys(map).join(', ') : '');
  }

  // next functions are for tags chip-list with autocomplete
  removeTag(tag: string): void {
    const index = this.obj.tags.indexOf(tag);
    if (index >= 0) {
      this.obj.tags.splice(index, 1);
      this.chipsDirty = true;
    }
  }
  addTagInput(event: MatChipInputEvent): void {
    if ((event.value || '').trim() && !this.tagAuto.options.some( i => i.active)) {
      if (!this.addTag(event.value.trim())) {
        // clear control also if no match
        this.tagInput.nativeElement.value = '';
        this.tagCtrl.setValue(null);
      }
    }
  }
  addTagAuto(event: MatAutocompleteSelectedEvent): void {
    this.addTag(event.option.viewValue);
  }
  addTag(tag: string): boolean {
    // allow create new tags
    if (!_.contains(this.allTags, tag)) {
      this.allTags.push(tag);
    }
    this.obj.tags.push(tag);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.chipsDirty = true;
    return true;
  }
  private _filterTags(value: string): string[] {
    const filterValue = value.toLowerCase();
    return _.chain(this.allTags)
    .filter( tag => tag.toLowerCase().indexOf(filterValue) === 0)
    .difference(this.obj.tags).value(); // avoid duplicate tags
  }

  submit() {
    console.log('submit', this.obj);
    this.save()
    .catch( e => this.notificationSvc.showError( "Dokument konnte nicht gespeichert werden: "+e ));
  }

  save() {
    if (this.obj.id) {
      return this.svc.update(this.obj).then( r => {window.history.back(); return this.obj.id; });
    } else {
      return this.svc.add(this.obj).then( r => {window.history.back(); return r.id; });
    }
  }

  cancel() {
    this.app.back()
  }
}
