
<!-- div ng-if="obj">
  <input placeholder="Item Title" class="form-control" [ngModel]="obj.title" required minlength="2" #title='ngModel' autofocus>
  <div ng-if="title.dirty">
    <span ng-if='title.errors; then errors else valid'>template renders here...</span>
  </div>

  <button class="btn btn-primary" (click)='create()' [disabled]="!title.valid">Create</button>
  <ng-template #valid>
  <p class="text-success">looks good!</p>
  </ng-template>

  <ng-template #errors>
  <p class="text-danger">form contains errors!</p>
  </ng-template>
</div -->

<div fxFlex></div>
<mat-card *ngIf="article" fxFlex="0 1 600px">
    <form #articleForm="ngForm" (ngSubmit)="submit()" fxLayout="column" fxLayoutAlign="start">
        
      <h3>Artikel bearbeiten</h3>
  
      <mat-form-field style="max-width:300px">    
        <input matInput type="text" placeholder="Titel" required [(ngModel)]="article.title" name="title">
      </mat-form-field>
  
      <mat-form-field style="max-width:300px">
        <mat-select placeholder="Typ"  required [(ngModel)]="article.type" name="type">
          <mat-option value="event">Veranstaltung</mat-option>
          <mat-option value="info">Information</mat-option>
          <mat-option value="survey">Umfrage</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="max-width:300px" *ngIf="article.type=='info' || article.type=='survey'">    
        <input matInput [matDatetimepicker]="expirationDatePicker" placeholder="Anzeigen bis" [(ngModel)]="expirationDate" name="expirationDate" autocomplete="false">
        <mat-datetimepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datetimepicker-toggle>
        <mat-datetimepicker type="date" openOnFocus="true" #expirationDatePicker></mat-datetimepicker>
      </mat-form-field>

      <mat-form-field style="max-width:300px" *ngIf="article.type=='event'">    
        <input matInput type="text" placeholder="Ort" required [(ngModel)]="article.location" name="location">
      </mat-form-field>
      <mat-form-field style="max-width:300px" *ngIf="article.type=='event'">    
        <input matInput [matDatetimepicker]="eventDatePicker" placeholder="Datum" required [(ngModel)]="eventDate" name="date" autocomplete="false">
        <mat-datetimepicker-toggle matSuffix [for]="eventDatePicker"></mat-datetimepicker-toggle>
        <mat-datetimepicker type="datetime" openOnFocus="true" timeInterval="15" #eventDatePicker></mat-datetimepicker>
      </mat-form-field>

      <!-- tinyMCE for event and info -->
      <div *ngIf="article.type=='event' || article.type=='info'">
        <editor apiKey="eufqbwg2nkufvf1o2na3vwj98o68rhla7jlt101xknzuo1es" [init]="editorConfig" [(ngModel)]="article.body" name="body"></editor>
        <br/>
      </div>

      <!-- textarea for survey definition -->
      <mat-form-field *ngIf="article.type=='survey'">
        <textarea matInput type="text" placeholder="Survey definition" required [(ngModel)]="article.body" name="body" style="min-height: 200px"></textarea>
        <mat-hint>Um die Survey definition zu editieren am einfachsten auf <a href="https://surveyjs.io/create-survey">https://surveyjs.io/create-survey</a> in den JSON Editor copy-pasten.</mat-hint>
      </mat-form-field>
      
      <mat-checkbox [(ngModel)]="article.active" [checked]="article.active" name="active">Aktiv<span class="small-text"> - Inaktive Einträge werden in Listen standardmässig nicht angzeigt</span></mat-checkbox>
      <mat-checkbox [(ngModel)]="article.public" [checked]="article.public" name="public">Publik<span class="small-text"> - Private Einträge sind nur für eingeloggte Benutzer sichtbar</span></mat-checkbox>
      <br/>

      <!-- categories -->
      <mat-form-field>
        <mat-chip-list #categoryList>
          <mat-chip *ngFor="let c of selectedCategories" removable="true" (removed)="removeCategory(c.id)">
            {{c.shortname || c.name || c.id}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input placeholder="Kategorien..." [matChipInputFor]="categoryList" #categoryInput [matAutocomplete]="categoryAuto" [formControl]="categoryCtrl"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true" (matChipInputTokenEnd)="addCategoryInput($event)">
        </mat-chip-list>
        <mat-autocomplete #categoryAuto="matAutocomplete" (optionSelected)="addCategoryAuto($event)">
          <mat-option *ngFor="let c of filteredCategories | async" [value]="c.id">
            {{c.shortname || c.name || c.id}}
          </mat-option>
        </mat-autocomplete>                 
      </mat-form-field>

      <!-- tags -->
      <mat-form-field>
        <mat-chip-list #tagList>
          <mat-chip *ngFor="let tag of article.tags" removable="true" (removed)="removeTag(tag)"> {{tag}} <mat-icon matChipRemove>cancel</mat-icon></mat-chip>
          <input placeholder="Tags..." [matChipInputFor]="tagList" #tagInput [matAutocomplete]="tagAuto" [formControl]="tagCtrl"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true" (matChipInputTokenEnd)="addTagInput($event)">
        </mat-chip-list>
        <mat-autocomplete #tagAuto="matAutocomplete" (optionSelected)="addTagAuto($event)">
          <mat-option *ngFor="let tag of filteredTags | async" [value]="tag"> {{tag}} </mat-option>
        </mat-autocomplete>                 
      </mat-form-field>     
      
      <!-- attachments -->
      <mat-form-field>
        <mat-list>
          <mat-chip-list #attachmentList  (click)="selectAttachment()">
            <mat-chip *ngFor="let attachment of attachments" removable="true" (removed)="removeAttachment(attachment.id)"> {{attachment.name}} [{{attachment.type}}] <mat-icon matChipRemove>cancel</mat-icon></mat-chip>
            <input placeholder="Anhänge..." [matChipInputFor]="attachmentList" readonly="true" style="max-width:15px">
          </mat-chip-list>
        </mat-list>      
      </mat-form-field>

      <!-- number of survey results -->
      <div *ngIf="surveyResults | async as surveyResultsVal">
        <p>Anzahl Umfrage Resultate: {{ surveyResultsVal.length }}<a mat-button (click)='exportSurveyResultsAsExcel(surveyResultsVal)'>Download</a></p>
      </div>

      <br/>
      <div>
        <button mat-raised-button (click)='cancel()' [disabled]="clicked">Abbrechen</button>
        <div fxFlex></div>
        <button mat-raised-button class="mat-primary button-margin" appPreventDoubleClick (throttledClick)='submitAndSend()' [disabled]="!articleForm.valid || !(articleForm.dirty || chipsDirty) || clicked || !article.active">Speichern und versenden</button>
        <div class="button-spacer"></div>
        <button mat-raised-button class="mat-primary" appPreventDoubleClick (throttledClick)='submit()' [disabled]="!articleForm.valid || !(articleForm.dirty || chipsDirty) || clicked">Speichern</button>
      </div>
    </form>
  </mat-card>
<div fxFlex></div>
