import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ObjDataSource } from 'src/app/shared/obj-data-source';
import { Media } from '../shared/media';
import { MediaService } from '../shared/media.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/admin/shared/config.service';
import { DateFormatShortPipe } from 'src/app/shared/date-format-short.pipe';
import { map, tap } from 'rxjs/operators';
import * as _ from 'underscore';
import { UserService } from 'src/app/user/shared/user.service';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/shared/app.service';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.css']
})
export class MediaListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource: ObjDataSource<Media>;
  public cols: string[] = [ 'type', 'name', 'tags' /*, 'thumbs'*/, 'active', 'public', 'updated', 'owner', 'actions'];
  public pageSize = 10; // for table paginator
  public tags = [];
  public objsLength = 0;

  // upload vars
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  //isHovering: boolean;  

  constructor( private svc: MediaService, private router: Router, private configSvc: ConfigService, public userSvc: UserService, public appSvc: AppService
             , private dateFormatShort: DateFormatShortPipe) {
    const objsObs = this.svc.getList().pipe(
      // collect tags from objs
      map( objs => _.sortBy( objs, obj => obj.tstmp).reverse()),
      tap( objs => {
        const tagNames = _.chain(objs).map( obj => obj.tags ).filter( tag => tag != null )
                          .flatten().unique().value();
        this.tags = tagNames.map( tagName => {
          return {id: tagName, selected: false };
        });
        this.objsLength = objs.length;
      })
    );
    this.dataSource = new ObjDataSource(objsObs, this.pageSize);
    this.dataSource.setResetPaginatorCallback( size => {
      if (this.paginator) this.paginator.firstPage();
      this.objsLength = size;
    })
  }

  ngOnInit() {
    this.sort.sortChange.subscribe( sort => this.dataSource.setSort(sort));
  }

  array2Str( arr: string[] ) {
    return (arr? arr.join(', ') : '');
  }

  mapkeys2Str( map: Map<string, string> ) {
    return (map? _.keys(map).join(', ') : '');
  }

  selectTag( tag ) {
    tag.selected = !tag.selected;
    this.dataSource.setFilter( this.getFilterFunc());
  }

  editObj( id: string ) {
    this.router.navigate(['mediaEdit/' + id]);
  }  

  private getFilterFunc(): (obj: Media) => boolean {
    // prepare selected tags
    const selectedTags = this.tags.filter( tag => tag.selected ).map( tag => tag.id );
    // return filter function
    return (obj: Media) => {
      return (selectedTags.length==0 ? true : _.intersection(selectedTags, obj.tags).length === selectedTags.length);
    };
  }

  // upload
  startUpload(event: FileList) {
    // The File object
    const file = event.item(0);

    // File type validation
    const fileType = file.type.split('/');
    let mediaType = '';
    if (fileType[0] === 'image') {
      mediaType = 'image';
    } else if (fileType[0] === 'application' && fileType[1] === 'pdf' ) {
      mediaType = 'pdf';
    } else {
      console.error('unsupported file type', file.type);
      return;
    }

    // Infos zusammenstellen
    const path = `${this.svc.name}/${new Date().getTime()}_${file.name}`; // The storage path
    const name = file.name.replace(/\.[^\.]*$/i, ''); // Endung aus Dateiname entfernen

    // start upload
    const media: Media = { name: name, url: 'tmp', path: path, type: mediaType, active: true, public: true };
    this.task = this.svc.upload( file, path, media);
    this.percentage = this.task.percentageChanges();
    this.task.task.then( result => this.percentage = null );
    //this.setDefaultSort();
  }

  tablePageChanged( event: PageEvent ) {
    this.dataSource.setOffset( event.pageIndex * this.pageSize );
  }  

}
