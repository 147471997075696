<div appDisableDrop>
<div mat-dialog-title>
  <div fxFlex class="align-left">
    <h2 mat-dialog-title>Dokument aus Bibliothek auswählen</h2>
  </div>
  <div fxFlex class="align-right">
    <button type="button" *ngIf="!renderAsList" (click)="setRenderAsList(true)" mat-icon-button><mat-icon>list</mat-icon></button>   
    <button type="button" *ngIf="renderAsList" (click)="setRenderAsList(false)" mat-icon-button><mat-icon>view_module</mat-icon></button>
  </div>
</div>
<mat-dialog-content>

  <!-- tags filter -->
  <div *ngIf="tags.length > 0" fxLayoutAlign="center" class="paddingM">
    <span fxFlexAlign="center">Filter&nbsp;</span>
    <mat-chip-list multiple="true">
      <mat-chip *ngFor="let t of tags" (click)="selectTag(t)" [selected]="t.selected">{{ t.name }}</mat-chip>
    </mat-chip-list>
  </div>
  <div class="paddingM table-container">
  
  <!-- render as cards -->
  <div *ngIf="!renderAsList" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center">
    
    <!-- upload card -->
    <mat-card class="upload-card" *ngIf='true' appDropZone dragOverClass="mat-elevation-z6" (dropped)="startUpload($event)" matTooltip="Eine neue Datei hierherziehen zum Upload">
      <mat-card-content *ngIf="!percentage" class="upload-card-content" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>add</mat-icon>
        <div class="center-text">Datei hier hinziehen</div>
      </mat-card-content>
      <mat-card-content *ngIf="percentage" class="upload-card-content" fxLayout="column" fxLayoutAlign="center center">
        <mat-progress-bar mode="determinate" [value]="percentage | async"></mat-progress-bar>
        <button id="btn-upload-cancel" (click)="task.cancel()" mat-mini-fab><mat-icon>clear</mat-icon></button>          
      </mat-card-content>
    </mat-card>

    <!-- existing pictures -->
    <mat-card class="upload-card" *ngFor="let obj of objs | async | slice:0:limit" appHoverClass="mat-elevation-z6">
      <img class="media-card-image" [src]="getThumbUrlSmall(obj)">
      <div class="media-card-footer" matTooltip="{{obj.name}}">{{obj.name}}</div>
      <mat-spinner *ngIf="!thumbsReady(obj)" [diameter]="20" matTooltip="Warten auf resizing..."></mat-spinner>      
      <button mat-mini-fab *ngIf="thumbsReady(obj)" class="media-btn-pick" (click)="pickMedia(obj)" matTooltip="Bild auswählen" color="primary"><mat-icon>done</mat-icon></button>          
      <button mat-mini-fab class="media-btn-delete" color="basic" [matMenuTriggerFor]="matImageMore" [matMenuTriggerData]="{obj: obj}"><mat-icon>more_vert</mat-icon></button>          
    </mat-card>
    <mat-menu #matImageMore="matMenu">
      <ng-template matMenuContent let-obj="obj">
        <button mat-menu-item (click)="svc.deactivate(obj)">Ausblenden</button>
        <button mat-menu-item (click)="svc.delete(obj)">Löschen</button>
      </ng-template>        
    </mat-menu>
  </div>

  <!-- render as list -->
  <div [hidden]="!renderAsList" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center" class="mat-elevation-z4">

    <mat-table [dataSource]="dataSource" matSort (dropped)="startUpload($event)" appDropZone>
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
        <mat-cell *matCellDef="let obj"> {{obj.id}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="type"> 
        <mat-header-cell *matHeaderCellDef mat-sort-header> Typ </mat-header-cell>
        <mat-cell *matCellDef="let obj"> {{obj.type}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Name </mat-header-cell>
        <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{obj.name}}</div> </mat-cell>
      </ng-container>
      <ng-container matColumnDef="updated">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Änderungszeit </mat-header-cell>
        <mat-cell *matCellDef="let obj"> {{obj.tstmp | dateFormatShort}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="owner">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Ersteller </mat-header-cell>
        <mat-cell *matCellDef="let obj"> {{obj.owner}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Aktiv </mat-header-cell>
        <mat-cell *matCellDef="let obj"><mat-icon *ngIf="obj.active">check</mat-icon></mat-cell>
      </ng-container>
      <ng-container matColumnDef="tags">
        <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
        <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{obj.tags.join(', ')}}</div> </mat-cell>
      </ng-container>
      <ng-container matColumnDef="thumbs">
        <mat-header-cell *matHeaderCellDef> Thumbs </mat-header-cell>
        <mat-cell *matCellDef="let obj"> <div class="cell-overflow">{{obj.thumbs.keys.join(', ')}</div>} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell *matHeaderCellDef>
          <mat-icon *ngIf="!percentage" mat-icon-button dragOverClass="mat-elevation-z6" matTooltip="Zum hochladen die neue Datei hierherziehen">add</mat-icon>
          <mat-progress-spinner *ngIf="percentage" mode="determinate" diameter="24" [value]="percentage | async" (click)="task.cancel()" matTooltip="Zum Abbrechen hier drücken"></mat-progress-spinner>
        </mat-header-cell>
        <mat-cell *matCellDef="let obj;" (click)="$event.stopPropagation()">
          <button type="button" (click)="svc.delete(obj)" mat-icon-button class="mat-row-button"><mat-icon>remove_circle_outline</mat-icon></button>
        </mat-cell>
      </ng-container>  
                
      <mat-header-row *matHeaderRowDef="cols"></mat-header-row>
      <div *ngIf="percentage" fxLayout="row" fxLayoutAlign="center center">
      </div>
      <mat-row *matRowDef="let obj; columns: cols;" (click)="pickMedia(obj)"></mat-row>
    </mat-table>
    <mat-paginator *ngIf="objsLength > tablePageSize" [length]="objsLength" [pageSize]="tablePageSize" hidePageSize="true" (page)="tablePageChanged($event)" showFirstLastButtons></mat-paginator>    
  </div>
</div>
</mat-dialog-content>

<mat-dialog-actions>
  <div fxFlex></div>
  <div fxFlex class="align-center">
    <button *ngIf="hasMore && !renderAsList" mat-mini-fab appPreventDoubleClick (throttledClick)="loadNextObjs()" matTooltip="Weitere Bilder laden" color="primary">
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>
  <div fxFlex class="align-right">
    <button *ngIf='true' mat-mini-fab appPreventDoubleClick (throttledClick)="close()" matTooltip="Abbrechen">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</mat-dialog-actions>
</div>