<div id="test" fxFlex>
<div fxFlex fxFlexFill></div>
<!-- Navigation bar -->
<mat-sidenav-container class="example-container" appDisableDrop fxFlex="0 1 1024px" fxFlexFill>
  <!-- side-nav for mobile -->
  <mat-sidenav #sidenav mode="over" color="primary">
    <div fxLayout="column">
      <a routerLink="home" mat-button routerLinkActive="navActive">Home</a>
      <a routerLink="org" mat-button routerLinkActive="navActive">Organisation</a>
      <a routerLink="more" mat-button routerLinkActive="navActive">Weiteres</a>
      <a routerLink="my" mat-button  routerLinkActive="navActive" *ngIf="isLoggedIn()">Mein EF</a>
      <a (click)="openLoginDialog()" *ngIf="!isLoggedIn()" mat-button>Login</a>
      <a *ngIf="isLoggedIn()" [matMenuTriggerFor]="subMenu" mat-button>Account</a>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- toolbar-nav for desktop -->
    <div id="header-img"></div>
    <mat-toolbar color="primary">
      <img id="logo-container-left" src="assets/logo_small.png" fxHide fxShow.gt-sm/>
      <a (click)="sidenav.toggle()" mat-icon-button fxShow fxHide.gt-sm><mat-icon>menu</mat-icon></a>
      <div *ngIf="!appSvc.isProduction()">TEST</div>
      <div fxFlex></div>
      <img id="logo-container-right" src="assets/logo_small.png" fxShow fxHide.gt-sm/>
      <div fxHide fxShow.gt-sm>
        <a routerLink="home" mat-button routerLinkActive="navActive">Home</a>
        <a routerLink="org" mat-button routerLinkActive="navActive">Organisation</a>
        <a routerLink="more" mat-button routerLinkActive="navActive">Weiteres</a>
        <a routerLink="my" mat-button  routerLinkActive="navActive" *ngIf="isLoggedIn()">Mein EF</a>
        <a (click)="openLoginDialog()" *ngIf="!isLoggedIn()" mat-button>Login</a>
        <a *ngIf="isLoggedIn()" [matMenuTriggerFor]="subMenu" mat-button class="profile-button"><mat-icon>account_circle</mat-icon></a>
      </div>
    </mat-toolbar>

    <!-- Profile submenu -->
    <mat-menu #subMenu="matMenu">
      <a (click)="logout()" mat-menu-item>Logout</a>
      <a routerLink="user" mat-menu-item>Profil</a>
      <a *ngIf="userSvc.isLoggedIn() && userSvc.userInfo.isEditor()" routerLink="articles" mat-menu-item>Artikel</a>
      <a *ngIf="userSvc.isLoggedIn() && userSvc.userInfo.isEditor()" routerLink="medias" mat-menu-item>Dokumente</a>
      <a *ngIf="userSvc.isLoggedIn() && (userSvc.userInfo.isAdmin() || userSvc.userInfo.isVorstand() || userSvc.userInfo.isConfirmedEv())" routerLink="users" mat-menu-item>Elternliste</a>
      <a *ngIf="userSvc.isLoggedIn() && userSvc.userInfo.isAdmin()" routerLink="admin" mat-menu-item>Admin</a>
    </mat-menu>

    <!-- Content -->
    <router-outlet ></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>    
<div fxFlex></div>
</div>
