// import { Directive } from '@angular/core';

import { Directive, ElementRef, Input, HostListener, Renderer2 } from '@angular/core';

/*
 * Adds a class to a DOM element when the mouse is over its box (:hover).
 * This DOESN'T support adding multiple classes separated by spaces.
 * Usage:
 *     <button class="btn" hoverClass="btn-success">
 */
@Directive({
  selector: '[appHoverClass]'
})
export class HoverClassDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @Input() appHoverClass: string;

  @HostListener('mouseenter') onMouseEnter() {
    this.renderer.addClass(this.el.nativeElement, this.appHoverClass);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.renderer.removeClass(this.el.nativeElement, this.appHoverClass);
  }
}
