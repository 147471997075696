
export class Image {
    name: string;
    path: string;
    url: string;
    created?: Date;
    updated?: Date;
    owner?: string;
    context: string;
    tags?: string[];
    active ? = true;
    thumbs?: Map<string, string>;
    id?: string;

    static getThumbUrl( obj: Image, size: string) {
        const thumbPath = (obj.thumbs ? obj.thumbs[size] : '');
        return (thumbPath ? obj.url.replace( encodeURIComponent(obj.path), encodeURIComponent(thumbPath)) : '' );
    }

    static getThumbUrlMedium( obj: Image ) {
        const thumbUrl = Image.getThumbUrl( obj, '1024');
        return ( thumbUrl ? thumbUrl : obj.url );
    }

    static getThumbUrlSmall( obj: Image ) {
        const thumbUrl = Image.getThumbUrl( obj, '256');
        return ( thumbUrl ? thumbUrl : Image.getThumbUrlMedium(obj));
    }
}
