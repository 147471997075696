import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Article } from '../shared/article';
import { ArticleService } from '../shared/article.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MediaService } from 'src/app/media/shared/media.service';
import { Media } from 'src/app/media/shared/media';
import { ConfigService } from 'src/app/admin/shared/config.service';
import { Category } from 'src/app/admin/shared/config';
import { UserService } from 'src/app/user/shared/user.service';
import { AppComponent } from 'src/app/app.component';
import { combineLatest, Observable, of } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification.service';
import { SurveyResult } from '../shared/surveyResult';
import { SurveyjsComponent } from './surveyjs.component';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.css']
})
export class ArticleDetailComponent implements OnInit {

  obj: Article;
  attachments: Media[] = [];
  attachmentsHidden = false;
  categories: Category[] = [];
  @ViewChild(SurveyjsComponent) public surveyComponent: SurveyjsComponent;

  constructor( private svc: ArticleService, private route: ActivatedRoute, private mediaSvc: MediaService, private notificationSvc: NotificationService
             , private configSvc: ConfigService, public userSvc: UserService, private router: Router, public app: AppComponent) { }

  ngOnInit() {
    combineLatest(this.route.params, this.configSvc.configObs).subscribe( ([params, config]) => {
      if (params['id']) {
        console.log('query id', params['id']);
        this.svc.getCached(params['id']).pipe(first()).subscribe( obj => {
          this.obj = obj;
          this.mediaSvc.getCachedAll(obj.attachmentRefs).subscribe( medias => {
            this.attachments = medias;
            // if we got less attachments than requested, and the user is not logged in, there are private attachmens which are not shown
            this.attachmentsHidden = !this.userSvc.isLoggedIn() && medias.length < obj.attachmentRefs.length
            console.log("attachmentsHidden", this.attachmentsHidden);
          });
          this.categories = this.obj.categories.map( id => this.configSvc.getCategory(id));
          console.log('show article', this.obj);
        });
      } else {
        console.error('parameter id not defined');
      }
    });
  }

  loadExistingSurveyResult(): Promise<SurveyResult> {
    return this.userSvc.getMySurveyResult(this.obj.id).pipe(first()).toPromise();
  }
    
  storeSurveyResult(result: SurveyResult) {
    result.id = this.obj.id;
    result.userId = this.userSvc.userInfo.user.id;
    console.log('store survey result', result);
    // JSON.stringify(result.data, null, 3)
    this.userSvc.addMySurveyResult(this.obj.id, result)
    .catch( err => this.notificationSvc.showError(`Error: ${err}`))
    .then( _ => this.notificationSvc.showInfo(`Antworten gespeichert`));
  }

  openAttachment(attachment: Media) {
    window.open( attachment.url, '_blank');
  }

  editObj( id: string ) {
    this.router.navigate(['edit/' + id]);
  }  

  cancel() {
    this.app.back();
  }
}